export const quesTypes = {
    '0': '單選',
    '1': '多選',
    '2': '填充',
    '3': '重組',
    '4': '朗讀',
    '4-1': '聽讀',
    '5': '對話',
    '6': '翻譯',
    '7': '寫作',
};
