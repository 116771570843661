import { render, staticRenderFns } from "./_school_info.vue?vue&type=template&id=6c05d7be&scoped=true&"
import script from "@/assets/js/components/admin/school_buying/common/_school_info.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/admin/school_buying/common/_school_info.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/admin/school_buying/common/_school_info.scss?vue&type=style&index=0&id=6c05d7be&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c05d7be",
  null
  
)

export default component.exports