import { render, staticRenderFns } from "./Translation.vue?vue&type=template&id=3c0331f4&scoped=true&"
import script from "@/assets/js/components/article/practicing/write/Translation.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/article/practicing/write/Translation.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/article/practicing/write/Translation.scss?vue&type=style&index=0&id=3c0331f4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0331f4",
  null
  
)

export default component.exports