import { quesTypes } from '@/assets/js/consts/article_practice'
import answerWrongAudio from '@/assets/sound/article/answer_wrong'  // base64

// common
const MediaPreviewer = resolve => require(["@/components/common/media_previewer.vue"], resolve)
// components
// v1
const MultipleChoice = resolve => require(["@/components/article/practicing/body/_multiple_choice.vue"], resolve)
const BlankFilling = resolve => require(["@/components/article/practicing/body/_blank_filling.vue"], resolve)
const WordsRearranging = resolve => require(["@/components/article/practicing/body/_words_rearranging.vue"], resolve)
const QuestionGroupPc = resolve => require(["@/components/article/practicing/body/_question_group_pc.vue"], resolve)
const QuestionGroupTablet = resolve => require(["@/components/article/practicing/body/_question_group_tablet.vue"], resolve)
// v2
const Header = resolve => require(["@/components/article/practicing/components/header.vue"], resolve)
const ReadAloud = resolve => require(["@/components/article/practicing/speak/ReadAloud.vue"], resolve)
const Conversation = resolve => require(["@/components/article/practicing/speak/Conversation.vue"], resolve)
const Translation = resolve => require(["@/components/article/practicing/write/Translation.vue"], resolve)
const Writing = resolve => require(["@/components/article/practicing/write/Writing.vue"], resolve)

export default {
    beforeCreate: function() {
        this.isShowFooter = false;
    },
    components: {
        MediaPreviewer,
        // v1
        Header,
        MultipleChoice,
        BlankFilling,
        WordsRearranging,
        QuestionGroupPc,
        QuestionGroupTablet,
        // v2
        ReadAloud,
        Conversation,
    },
    data: function() {
        return {
            pageUrl: location.href,
            audioElem: new Audio(),
            playPromise: null,
            playingAudioInfo: {},
            groupQuestionAudio: new Audio(),
            isPlayingGroupQuesAudio: false,

            previewingMediaInfo: {},

            activeQuestionIndex: 0,  // 目前正在進行的題目 index
            questionStartTime: 0,  // 題目的開始時間
            questionEndTime: 0,  // 題目的結束時間

            isAnswerCardCollapsed: true,  // 是否收合答案卡
            answerSelectedOptionId: 0,  // 單選選擇的選項 id
            answerSelectedOptionIds: [],  // 多選選擇的選項 ids
            answerInputContent: '',  // 填充填入輸入框的文字
            answerSelectedOptionItems: [],  // 重組選擇的單字選項物件
            answerHandInOptionIds: [],  // 選擇/重組題選擇的選項 ids (送出答案使用)
            handInResult: {},  // 每一題作答結果
            oldHandInResult: {},  // 每一題前一次的作答結果
            isShowHandInFeedback: false,  // 是否顯示答對或答錯回饋
            isCommentCardExpanded: false,  // 是否展開解析內容卡片

            // 題組題
            activeChildQuesIndex: 0,  // 目前正在進行的子題 index
            allChildQuesCount: 0,  // 題組中所有子題總數
            remainingChildQuesCount: 0,  // 題組中已練習過的子題總數
            currentQuestionScrollTop: 0,  // 目前題幹卷軸位置

            correctAnswerAudio: {  // 答對音效
                url: require('@/assets/sound/article/answer_correct.wav'),
                isPlayingSound: false
            },
            wrongAnswerAudio: {  // 答錯音效
                url: answerWrongAudio.dataURI,
                isPlayingSound: false
            },

            renderAnswerTypesKey: {
                multipleChoice: 0,  // 單/多選
                blankFilling: 0,  // 填充
                wordsRearranging: 0,  // 重組
                questionGroupPc: 0,  // 題組-非平板
                questionGroupTablet: 0  // 題組-平板
            },

            isPostingApi: {
                handInAnswer: false,  // 送出作答
            },

            // v2
            testedId: '',
            isShowScore: false,
            scoreType: '',
            questions: [],
            basicQuestions: [],
            tempHandedInfo: {},
            handedId: '',
            activeIndex: -1,
            headerInfo: {
                groupNo: 0,
                quesNo: 0,
                quesType: '',
                score: '',
                progress: '',
            },
            isSetDataReady: {
                getQuestions: false,  // 取得所有題目
            },
            practiceTimerId: null,      // 題目作答時間計時器的 interval id
            practiceTime: 0,            // 題目作答秒數
        }
    },
    watch: {
        windowWidth(newValue) {
            // v1
            if (!this.$route.params.version && this.practiceQuestionInfo.categoryScoreType === '2') {
                if (newValue >= 992) {
                    this.renderAnswerTypesKey.questionGroupPc++;
                } else {
                    this.renderAnswerTypesKey.questionGroupTablet++;
                }
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isShowFooter: {
            get() {
                return this.$store.state.common.isShowFooter;
            },
            set(value) {
                this.$store.commit('common/setIsShowFooter', value);
            }
        },
        isShowMediaPreviewer: {
            get() {
                return this.$store.state.common.isShowMediaPreviewer;
            },
            set(value) {
                this.$store.commit('common/setIsShowMediaPreviewer', value);
            }
        },
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        articleData() {
            return this.$store.state.article.articleData;
        },
        isShowNotSavePrompt: {
            get() {
                return this.$store.state.article.isShowNotSavePrompt;
            },
            set(value) {
                this.$store.commit('article/setIsShowNotSavePrompt', value);
            }
        },
        articlePracticeInfo() {
            return this.$store.state.article.articlePracticeInfo;
        },
        isPracticing: {
            get() {
                return this.$store.state.article.isPracticing;
            },
            set(value) {
                this.$store.commit('article/setIsPracticing', value);
            }
        },
        isShowNotSavePracticeHistoryPrompt: {
            get() {
                return this.$store.state.article.isShowNotSavePracticeHistoryPrompt;
            },
            set(value) {
                this.$store.commit('article/setIsShowNotSavePracticeHistoryPrompt', value);
            }
        },
        // v1
        practiceQuestionInfo() {
            return this.$store.state.article.practiceQuestionInfo;
        },
        activeQuestion() {
            if (this.$route.params.version) return;

            return this.practiceQuestionInfo.questions[this.activeQuestionIndex];
        },
        activeChildQuestion() {
            if (this.$route.params.version) return;

            return this.activeQuestion.childQuestions[this.activeChildQuesIndex];
        },
        isLastQuestion() {
            if (this.$route.params.version) return;

            // 題組題分類, 需到達最後一題幹的最後一子題, 才為最後一題
            if (this.practiceQuestionInfo.categoryScoreType === '2') {
                return this.remainingChildQuesCount === 0;
            }

            return this.activeQuestionIndex === (this.practiceQuestionInfo.questions.length - 1);
        },
        activeWordItemIndex() {
            let index = 0;
            this.answerSelectedOptionItems.some((el, i) => {
                if (!el.text) {
                    index = i;
                    return true;
                }
            });
            return index;
        },
        questionContentType() {
            if (this.$route.params.version) return;

            // 取得題目或題幹內容的組成類型
            let type = this.articleUtil.setQuestionContentType(this.activeQuestion.title);

            return type;
        },
        childQuestionContentType() {
            if (this.$route.params.version) return;

            // 取得題組子題內容的組成類型
            let type = this.articleUtil.setQuestionContentType(this.activeChildQuestion.title);

            return type;
        },
        choiceOptionContentTypeInfo() {
            if (this.$route.params.version) return;

            // 取得各選項內容的組成資訊
            let info = 0;

            // 題組題子題各選項
            if (this.practiceQuestionInfo.categoryScoreType === '2') {
                info = this.articleUtil.setChoiceOptionContentTypeInfo(this.activeChildQuestion.options);
            } else {
                info = this.articleUtil.setChoiceOptionContentTypeInfo(this.activeQuestion.options);
            }

            return info;
        },
        minOptionsCount() {
            if (this.$route.params.version) return;

            // 單選/多選
            if (['0', '1'].includes(this.activeQuestion.answerType)) {
                return 4;
            }
            // 重組
            if (this.activeQuestion.answerType === '3') {
                return 6;
            }
        },
        isShowHandinBtn() {
            if (this.$route.params.version) return;

            // 如果該題已對完答案, 則隱藏送出按鈕
            // 如果該題尚未對答案, 則送出答案按鈕顯示時機:
            // 1. 單選/多選/題組內的單選或多選: 有選擇選項時
            // 2. 填充: 輸入框有填寫內容時
            // 3. 重組: 有選擇選項至擺放單字區塊時

            if (!this.$_.isEmpty(this.handInResult)) {
                return false;
            }

            // 單選/多選
            if (['0', '1'].includes(this.activeQuestion.answerType)) {
                return !!(this.answerSelectedOptionId || this.answerSelectedOptionIds.length);
            }
            // 填充
            if (this.activeQuestion.answerType === '2') {
                return !!this.answerInputContent;
            }
            // 重組
            if (this.activeQuestion.answerType === '3') {
                let isAllItemHasText = true;
                this.answerSelectedOptionItems.some(el => {
                    if (!el.text) {
                        isAllItemHasText = false;
                        return true;
                    }
                });
                return isAllItemHasText;
            }

            return false;
        },

        // v2
        practicingCategory() {
            return this.$store.state.article.practicingCategory;
        },
        componentType() {
            switch (this.headerInfo.quesType) {
                case '朗讀': case '聽讀': { return ReadAloud }
                case '對話': { return Conversation }
                case '翻譯': { return Translation }
                case '寫作': { return Writing }
            }
        },
    },
    created: function() {
        this.isShowNotSavePrompt = true;
        this.isShowNotSavePracticeHistoryPrompt = true;

        // v1
        if (!this.$route.params.version) {
            // 題組題分類, 需計算子題總數
            if (this.practiceQuestionInfo.categoryScoreType === '2') {
                for (let el of this.practiceQuestionInfo.questions) {
                    this.allChildQuesCount += el.childQuestions.length;
                }
                this.remainingChildQuesCount = this.allChildQuesCount;
            }
        }
        // v2
        else {
            this.getQuestions(this.practicingCategory.id);
        }
    },
    mounted: function() {
        // v1
        if (!this.$route.params.version) {
            this.registerAudioEvent();
            this.initQuestion();
        }

        this.$nextTick(() => {
            $('body').css({ 'overflow': 'hidden' });  // 鎖住自動產生外卷軸
        });
    },
    beforeDestroy: function() {
        this.isShowFooter = true;

        this.isPracticing = false;
        this.isShowNotSavePrompt = false;
        this.isShowNotSavePracticeHistoryPrompt = false;

        this.pauseAudio();
        this.groupQuestionAudio.pause();

        $('body').css({ 'overflow': '' });
    },
    methods: {
        // 題目內文字項目的 DOM 設定
        setQuestionTextItemJustifyContent() {
            // 題組題分類不適用
            if (this.practiceQuestionInfo.categoryScoreType !== '0') {
                return;
            }
            // 題目內容只有單獨 text 適用
            if (this.questionContentType !== 2) {
                $('.question-content-wrapper .question-content').css({ justifyContent: '' });
                return;
            }
            // 單選/多選/填充才做以下處理
            if (!['0', '1', '2'].includes(this.activeQuestion.answerType)) {
                return;
            }

            $('.question-content-wrapper .question-content .text-item').each(function() {
                if ($(this).height() > $(this).parent().height()) {
                    $(this).parent().css({ justifyContent: 'flex-start' });
                } else {
                    $(this).parent().css({ justifyContent: 'center' });
                }
            });
        },
        // 重組題擺放單字項目 DOM 設定
        setQuestionWordItemWidth() {
            // 重組才做以下處理
            if (this.activeQuestion.answerType !== '3') {
                return;
            }

            let list = $('.question-content-wrapper .question-content .separated-word-list');
            let offset = 70;  // gap(10) * 6 + scrollbar padding(10)
            let listWidth = list.width() - offset;
            $('.question-content-wrapper .question-content .separated-word-list .word-item.is-editable').each(function() {
                $(this).css({ width: listWidth / 6 });
            });

            // 移除有填入選項的單字空格寬度
            $('.question-content-wrapper .question-content .separated-word-list .word-item.is-filled').each(function() {
                $(this).css({ width: '' });
            });
        },
        // 題目內圖片項目的 DOM 設定
        setQuestionImageItemMaxWidth() {
            // 題組題分類不適用
            if (this.practiceQuestionInfo.categoryScoreType !== '0') {
                return;
            }
            // 題目內容含有 image 適用
            if (![3, 5, 6, 7].includes(this.questionContentType)) {
                $('.question-content-wrapper .question-content div').css({ maxWidth: '', height: '' });
                return;
            }

            let quesAnswerType = this.activeQuestion.answerType;

            $('.question-content-wrapper .question-content .image-item').each(function() {
                let audioItemHeight = $('.question-content-wrapper .question-content .audio-item').height();
                let textItemHeight = $('.question-content-wrapper .question-content .text-item').height();

                let offset = 0;
                if (audioItemHeight) {
                    offset += (audioItemHeight + 20);
                }
                if (textItemHeight) {
                    offset += (textItemHeight + 20);
                }

                // 重組題需考慮擺放單字的區塊
                if (quesAnswerType === '3') {
                    let separatedWordsListHeight = $('.question-content-wrapper .question-content .separated-word-list').height();
                    offset += (separatedWordsListHeight + 20);
                }

                let height = Math.floor(($(this).parent().height() - offset) * 0.9);
                if (height > 40) {
                    $(this).css({ height: height });
                    let maxWidth = Math.floor($(this).height() * 4 / 3);
                    $(this).css({ maxWidth: maxWidth });
                }
                // 若經計算後, 高度已 < 40 的狀況時, 則直接將圖片項目的寬高設為 320x240
                else {
                    $(this).css({ maxWidth: '' }).css({ width: 320, height: 240 });
                }
            });
        },

        // 單選/多選/重組選項列表或選項項目的 DOM 設定
        // 答案內容區塊的 DOM 設定
        setAnswerContentBlockHeight() {
            $('.practicing-answer-card .answer-content-block').each(function() {
                $(this).css({ minHeight: '' });
            });

            // 重組題適用
            if (this.activeQuestion.answerType !== '3') {
                return;
            }
            // 有作答結果後才做以下處理
            if (this.$_.isEmpty(this.handInResult)) {
                return;
            }

            let answerCardHeight = ($(window).height() - 120) / 2;

            let standardAnswerInfoCardHeight = 0;
            // 送出後取得作答結果並且答錯才會顯示正確答案區塊
            if (!this.$_.isEmpty(this.handInResult) && !this.handInResult.isCorrect) {
                standardAnswerInfoCardHeight = $('.bottom-info-card.standard-answer').height();
            }

            let offset = 90;  // 90 = 40(.card-content-wrapper 上下padding) + 50(bottom-operating-btn)
            let setHeight = answerCardHeight - standardAnswerInfoCardHeight - offset;
            $('.practicing-answer-card .answer-content-block').each(function() {
                $(this).css({ minHeight: setHeight });
            });

            this.setOptionListAndItemHeight();
        },
        setOptionListAndItemHeight() {
            let quesAnswerType = this.activeQuestion.answerType;

            // 單選/多選/重組才做以下處理
            if (!['0', '1', '3'].includes(quesAnswerType)) {
                return;
            }

            let windowHeight = $(window).height();
            let childQuesHeight = $('.answer-content-block .child-question-content').outerHeight(true);  // 多選題顯示 (多選) 區塊
            let listHeight;
            let offset = 40;  // 答案卡內容(.card-content-wrapper)的上下padding
            if (childQuesHeight) {
                listHeight = (windowHeight - 120) / 2 - offset - childQuesHeight;
            } else {
                // 重組題, 每題作答後
                if (quesAnswerType === '3' && !this.$_.isEmpty(this.handInResult)) {
                    offset += 50;  // 下方操作按鈕(.bottom-operating-btn)

                    // 若答錯時, 選項內容高度需扣除正確答案區塊
                    if (!this.handInResult.isCorrect) {
                        offset += $('.bottom-info-card.standard-answer').height();
                    }
                }
                listHeight = (windowHeight - 120) / 2 - offset;
            }
            $('.choose-options-list').css({ height: listHeight });

            let isDoubleCols = this.choiceOptionContentTypeInfo.isDoubleCols;
            $('.choose-options-list .option-item').each(function() {
                if (quesAnswerType === '3') {
                    let setHeight = listHeight / 3.5 >= 50 ? listHeight / 3.5 : 80;
                    $(this).css({ minHeight: setHeight });
                } else {
                    if (isDoubleCols) {
                        $(this).css({ minHeight: listHeight / 2 });
                    } else {
                        let setHeight = listHeight / 4 >= 50 ? listHeight / 4 : 80;
                        $(this).css({ minHeight: setHeight });
                    }
                }
            });
        },
        setOptionTextItemStyle() {
            let quesAnswerType = this.activeQuestion.answerType;

            // 單選/多選/重組才做以下處理
            if (!['0', '1', '3'].includes(quesAnswerType)) {
                return;
            }
            // 選項內容組成成分皆為「單獨 text」才做以下處理
            let isContinue = false;
            this.activeQuestion.options.some(el => {
                if (this.choiceOptionContentTypeInfo.option[el.id] !== 1) {
                    isContinue = false;
                    return true;
                } else {
                    isContinue = true;
                    return false;
                }
            });
            if (!isContinue) {
                return;
            }

            // pattern
            let regex = [];
            regex[0] = /[A-Za-z0-9]+/gm;  // 英文字 + 數字
            regex[1] = /[\u0020|\u0021-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E]+/gm;  // 半形空白 | 半形標點符號
            regex[2] = /[\u4E00-\u9FA5]+/gm;  // 中文字
            regex[3] = /[\u3000|\uFF01-\uFF5E]+/gm;  // 全形空白 | 全形標點符號

            let totalWordWidthCount = 0;
            let isFontSizeMd = false, isFontSizeSm = false;
            let setFontSize = 24, setLineHeight = 24;

            $('.choose-options-list .option-content .text-item').each(function() {
                let sentence = $(this).html().trim();
                // console.log(sentence);

                let count = [];
                for (let i in regex) {
                    let matchResults = sentence.match(regex[i]);
                    if (matchResults) {
                        let totalLength = 0;
                        for (let j of matchResults) {
                            totalLength += j.length;
                        }
                        count[i] = totalLength;
                    } else {
                        count[i] = 0;
                    }
                }

                totalWordWidthCount = (count[0] + count[1]) + (count[2] + count[3]) * 2;
                // console.log(totalWordWidthCount);

                // 依據半形字數多寡, 以決定選項文字呈現的字體大小與行高
                if (totalWordWidthCount > 100) {
                    isFontSizeSm = true;
                } else if (totalWordWidthCount > 50 && totalWordWidthCount <= 100) {
                    isFontSizeMd = true;
                }
            });

            // 設定欲呈現的文字字體大小與行高
            if (isFontSizeSm) {
                setFontSize = 16;
                setLineHeight = 20;
            } else if (isFontSizeMd) {
                setFontSize = 18;
                setLineHeight = 24;
            } else {
                setFontSize = 24;
                setLineHeight = 24;
            }

            // 若改變顯示解析度, 需對應變化字體大小與行高
            $('.choose-options-list .option-content .text-item').each(function() {
                $(this).css({ fontSize: `${setFontSize}px`, lineHeight: `${setLineHeight}px` });

                let optionContent = $(this).parent().parent();
                let textItemHeight = $(this).height();

                if (optionContent.height() >= 40) {
                    // 若文字內容高度 > 選項外框高度, 則將文字縮小 2px, 以讓所有內容能放進框內
                    while (textItemHeight > optionContent.height()) {
                        let tempFontSize = parseInt($(this).css('font-size'));
                        setFontSize = tempFontSize -= 2;
                        $(this).css({ fontSize: `${setFontSize}px` });

                        textItemHeight = $(this).height();
                    }
                }
            });

            // 將所有選項的文字大小統一為最小的字級與行高
            $('.choose-options-list .option-content .text-item').each(function() {
                $(this).css({ fontSize: `${setFontSize}px`, lineHeight: `${setLineHeight}px` });
            });
        },
        setOptionImageItemMaxWidth() {
            // 單選/多選才做以下處理
            if (!['0', '1'].includes(this.activeQuestion.answerType)) {
                return;
            }

            this.$nextTick(() => {
                $('.choose-options-list .option-content .image-item').each(function() {
                    let maxWidth = Math.floor($(this).height() * 4 / 3);
                    $(this).css({ maxWidth: maxWidth });
                });
            });
        },

        // 播放/暫停音檔
        registerAudioEvent() {
            // 註冊音檔播放/結束事件
            this.audioElem.onplaying = () => {
                this.playingAudioInfo.isPlayingSound = true;
            };
            this.audioElem.onended = () => {
                this.playingAudioInfo.isPlayingSound = false;
                if (this.activeQuestion.answerType === '4') {
                    setTimeout(() => this.isShowHandInFeedback = false, 700);
                } else {
                    this.isShowHandInFeedback = false;
                }
                $('.answer-input').focus();
            };
        },
        playAudio(audioInfo, isDataURISrc = false) {
            $('.lms-btn').blur();

            if (audioInfo.isPlayingSound) {
                return;
            }

            if (this.isPlayingGroupQuesAudio) {
                this.groupQuestionAudio.pause();
            }

            if (!this.$_.isEmpty(this.playingAudioInfo)) {
                this.playingAudioInfo.isPlayingSound = false;
            }
            this.playingAudioInfo = audioInfo;

            if (audioInfo.url) {
                this.audioElem.src = isDataURISrc ? 'data:audio/wav;base64,' + audioInfo.url : audioInfo.url;
                if (this.audioElem.paused) {
                    this.playPromise = this.audioElem.play();

                    // 判斷音檔來源如果無效, 需提示為無效的音檔來源
                    if (this.playPromise && this.playPromise !== undefined) {
                        this.playPromise
                            .catch((error) => {
                                if (error.name === 'NotSupportedError') {
                                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                                }
                                console.error('Catched Error:', error);
                            });
                    }
                }
            } else {
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
            }
        },
        pauseAudio() {
            $('.lms-btn').blur();

            if (this.playPromise && this.playPromise !== undefined) {
                this.playPromise
                    .then(() => {
                        this.audioElem.pause();
                        this.playingAudioInfo.isPlayingSound = false;
                    })
                    .catch(error => {
                        console.error('Catched Error:', error);
                    });
            }
        },
        playGroupQuestionAudio(audioInfo) {
            if (!audioInfo.url) return;

            if (this.playingAudioInfo.isPlayingSound) {
                this.pauseAudio();
            }

            this.groupQuestionAudio.onplaying = () => {
                audioInfo.isPlayingSound = true;
                this.isPlayingGroupQuesAudio = true;
            };
            this.groupQuestionAudio.onpause = () => {
                audioInfo.isPlayingSound = false;
                this.isPlayingGroupQuesAudio = false;
            };
            this.groupQuestionAudio.src = audioInfo.url;

            try {
                this.groupQuestionAudio.volume = 0.5;
                this.groupQuestionAudio.play();
            } catch (error) {
                if (error.name === 'NotSupportedError') {
                    this.$store.dispatch('common/setAlert', { status: 'danger', msg: '無效的音檔來源' });
                }
                console.error('Catched Error:', error);
            }
        },

        // 預覽影片/圖片
        previewMedia(mediaInfo, isMediaLoadedError = false) {
            if (isMediaLoadedError) {
                return;
            }
            if (this.$_.isEmpty(mediaInfo) ||
                !this.$_.has(mediaInfo, 'type') || !mediaInfo.type ||
                !this.$_.has(mediaInfo, 'url') || !mediaInfo.url) {
                return;
            }

            this.previewingMediaInfo = {
                type: mediaInfo.type,
                url: mediaInfo.url,
            };

            if (mediaInfo.type === 'image') {
                this.previewingMediaInfo.aspectRatio = '4-3';
            } else {
                this.groupQuestionAudio.pause();
            }

            this.isShowMediaPreviewer = true;
        },

        initQuestion() {
            // 初始設定值
            this.isAnswerCardCollapsed = true;
            this.answerSelectedOptionId = 0;
            this.answerSelectedOptionIds = [];
            this.answerInputContent = '';
            // 重組需依選項數目填入陣列
            if (this.activeQuestion.answerType === '3') {
                this.answerSelectedOptionItems = [];
                for (let i = 0; i < this.activeQuestion.options.length; i++) {
                    this.answerSelectedOptionItems.push({
                        id: null,
                        text: ''
                    });
                }
            }
            this.answerHandInOptionIds = [];
            this.handInResult = {};
            this.oldHandInResult = {};
            this.isShowHandInFeedback = false;
            this.isCommentCardExpanded = false;
            // 題組題
            this.remainingChildQuesCount--;

            // 依據計分類型與作答類型, 重新渲染子元件
            switch (this.practiceQuestionInfo.categoryScoreType) {
                case '0':  // 一般分類
                    switch (this.activeQuestion.answerType) {
                        case '0': case '1':  // 單/多選
                            this.renderAnswerTypesKey.multipleChoice++;
                            break;
                        case '2':  // 填充
                            this.renderAnswerTypesKey.blankFilling++;
                            break;
                        case '3':  // 重組
                            this.renderAnswerTypesKey.wordsRearranging++;
                            break;
                    }
                    break;
                case '2':  // 題組分類
                    if (this.windowWidth >= 992) {
                        this.renderAnswerTypesKey.questionGroupPc++;
                    } else {
                        this.renderAnswerTypesKey.questionGroupTablet++;
                    }
                    break;
            }

            // 題目開始計時
            this.practiceTime = 0;
            this.practiceTimerId = setInterval(() => this.practiceTime++, 1000);
        },

        // 重組題
        combineSentence(option) {
            // 重組題適用
            if (this.activeQuestion.answerType !== '3') {
                return;
            }

            let tempOption = {
                id: option.id,
                text: option.text
            }
            this.$set(this.answerSelectedOptionItems, this.activeWordItemIndex, tempOption);
            this.$nextTick(() => {
                this.setQuestionWordItemWidth();

                if (this.isShowHandinBtn) {
                    this.setAnswerContentBlockHeight();
                }
            });

            option.isDisableSelected = true;
        },
        restoreWordToOption(word, itemIndex) {
            // 重組題適用
            if (this.activeQuestion.answerType !== '3') {
                return;
            }
            // 尚未填入單字的單字空格不適用
            if (this.$_.isNull(word.id)) {
                return;
            }
            // 尚未對答案才能恢復單字選項
            if (!this.$_.isEmpty(this.handInResult)) {
                return;
            }

            let initOption = {
                id: null,
                text: ''
            }
            this.$set(this.answerSelectedOptionItems, itemIndex, initOption);
            this.$nextTick(() => {
                this.setQuestionWordItemWidth();
            });

            let findIndex;
            this.activeQuestion.options.some((el, i) => {
                if (word.id === el.id) {
                    findIndex = i;
                    return true;
                }
            });
            if (findIndex !== undefined) {
                this.activeQuestion.options[findIndex].isDisableSelected = false;
            }
        },

        handInAnswer() {
            if (['0', '2'].includes(this.practiceQuestionInfo.categoryScoreType) && this.isPostingApi.handInAnswer) {
                return;
            }

            this.pauseAudio();

            let params = new FormData();

            // 單選
            if (this.activeQuestion.answerType === '0') {
                this.answerHandInOptionIds = [];
                this.answerHandInOptionIds.push(this.answerSelectedOptionId);
            }
            // 多選
            if (this.activeQuestion.answerType === '1') {
                this.answerHandInOptionIds = this.answerSelectedOptionIds;
            }
            // 重組
            let answerHandInWords = [];
            if (this.activeQuestion.answerType === '3') {
                for (let el of this.answerSelectedOptionItems) {
                    answerHandInWords.push(el.text);
                }
            }

            let answer = {
                practiceTestedId: this.practiceQuestionInfo.testedId,
                questionId: this.practiceQuestionInfo.categoryScoreType === '2' ? this.activeChildQuestion.id : this.activeQuestion.id,  // 題組題為子題 id
                answerOption: {
                    answerContent: this.answerInputContent,
                    answerIds: this.answerHandInOptionIds,
                    answerWords: answerHandInWords,
                    answerCompositions: [],
                },
                time: this.practiceTime,
            }
            clearInterval(this.practiceTimerId);    // 清除題目作答時間計時器
            this.practiceTimerId = null;

            params.append('answer', JSON.stringify(answer));

            this.isPostingApi.handInAnswer = true;

            this.$httpRequest.post('/api/practice/handin', params)
                .then(response => {
                    this.isPostingApi.handInAnswer = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            // 單選, 多選, 填充, 重組, 題組題單、多選
                            $('.answer-content-block').scrollTop(0);  // 答案卡卷軸置頂

                            if (!this.practiceQuestionInfo.isShowFeedback) {
                                this.changeToNextQuestion();
                                return;
                            }

                            // 解析媒體
                            let tempCommentFile = {};
                            if (!this.$_.isEmpty(result.comment) &&
                                result.comment.file_url &&
                                result.comment.file_type) {
                                tempCommentFile = {
                                    type: result.comment.file_type.split('/')[0],
                                    url: result.comment.file_url
                                }
                                if (tempCommentFile.type === 'audio') {
                                    tempCommentFile.isPlayingSound = false;  // 是否正在播放音檔
                                }
                            }
                            // 解析資訊
                            let tempComment = null;
                            if (!this.$_.isNull(result.comment)) {
                                tempComment = {};
                                tempComment = {
                                    file: tempCommentFile,
                                    text: result.comment.text
                                }
                            }

                            this.handInResult = {
                                isCorrect: result.is_correct,
                                isHasComment: !this.$_.isNull(result.comment),  // 是否有解析
                                answerInfo: {
                                    optionStatusStyle: this.articleUtil.setHandInOptionStatusStyle(result.right_answer.right_option_status, this.answerHandInOptionIds),  // 單選/多選/題組題單、多選適用, key name 為 選項 id
                                    answerContents: result.right_answer.right_answer_content  // 填充/重組題正確答案
                                },
                                comment: tempComment
                            }

                            // 重組題 DOM 設定
                            this.$nextTick(() => this.setAnswerContentBlockHeight());

                            // 播放答對/答錯音效
                            this.isShowHandInFeedback = true;
                            if (!this.isPlayingGroupQuesAudio) {
                                if (this.handInResult.isCorrect) {
                                    this.playAudio(this.correctAnswerAudio);
                                } else {
                                    this.playAudio(this.wrongAnswerAudio, true);
                                }
                            } else {
                                setTimeout(() => this.isShowHandInFeedback = false, 1000);
                            }

                            // 如果最後一題作答完畢後, 則不需在離開頁面時顯示系統不自動儲存提示
                            if (this.isLastQuestion) {
                                this.isShowNotSavePrompt = false;
                                this.isShowNotSavePracticeHistoryPrompt = false;
                            }
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.handInAnswer = false;
                    console.error('Catched Error:', error);
                });
        },

        collapseAnswerCard() {
            this.isAnswerCardCollapsed = !this.isAnswerCardCollapsed;
            $('.answer-content-block').scrollTop(0);
        },
        expandCommentCard() {
            this.pauseAudio();
            this.isCommentCardExpanded = !this.isCommentCardExpanded;
        },

        async changeToNextQuestion() {
            $('.lms-btn.bottom-operating-btn').blur();

            this.pauseAudio();

            // 若為最後一題, 則導到作答後檢視回顧頁面
            if (this.isLastQuestion) {
                this.isShowNotSavePrompt = false;
                this.isShowNotSavePracticeHistoryPrompt = false;
                this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practice-history/${this.practiceQuestionInfo.categoryId}?tested=${this.practiceQuestionInfo.testedId}`);
                return;
            }

            // 題組題分類
            if (this.practiceQuestionInfo.categoryScoreType === '2') {
                // 已到達該題組的最後一子題, 需跳至下一題組
                if (this.activeChildQuesIndex === (this.activeQuestion.childQuestions.length - 1)) {
                    this.groupQuestionAudio.pause();
                    this.currentQuestionScrollTop = 0;
                    this.activeQuestionIndex++;
                    this.activeChildQuesIndex = 0;
                } else {
                    this.currentQuestionScrollTop = $('.practicing-question .question-content').scrollTop();
                    this.activeChildQuesIndex++;
                }
            }
            // 非題組題分類
            else {
                this.activeQuestionIndex++;
            }

            this.initQuestion();
        },

        // v2
        getQuestions(categoryId) {
            if (!categoryId) {
                console.error('Get Questions Error: categoryId is empty');
                return;
            }

            const params = {
                articleId: this.$route.params.id,
                practiceCategoryId: categoryId,
            };

            this.$httpRequest.get('/api/practice/get_practice_question', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            if (result.practice_category_id !== categoryId) {
                                console.error('Get Questions Error: categoryId is wrong');
                                return;
                            }

                            this.testedId = result.practice_tested_id;
                            this.isShowScore = result.is_show_score;
                            this.scoreType = result.score_type;

                            this.questions = result.questions;
                            const qIndex = +result.testing_index;
                            if (this.scoreType === '2') {  // 題組
                                for (let i = 0; i < this.questions; i++) {
                                    const q = this.questions[i];
                                    for (const sq of q.sub_questions) {
                                        this.basicQuestions.push({
                                            id: sq.id,
                                            groupNo: i + 1,
                                            quesType: quesTypes[sq.answer_type],
                                            score: sq.score,
                                        });
                                    }
                                }
                            } else {
                                for (const q of this.questions) {
                                    const answerType = (q.answer_type === '4') && !q.is_show_speak_text ? '4-1' : q.answer_type;

                                    this.basicQuestions.push({
                                        id: q.id,
                                        groupNo: 0,
                                        quesType: quesTypes[answerType],
                                        score: q.score,
                                    });
                                }
                            }
                            this.tempHandedInfo = result.temp_answer;
                            this.handedId = result.ptq_id;

                            this.isSetDataReady.getQuestions = true;
                            this.getNextQuestion(qIndex);
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        getNextQuestion(qIndex = null) {
            if (qIndex !== null) {
                this.activeIndex = qIndex;
            } else {
                this.activeIndex++;
            }
            let q = this.basicQuestions[this.activeIndex];

            // 結束作答
            if (!q) {
                // 導向練習紀錄頁面
                this.isShowNotSavePrompt = false;
                this.isShowNotSavePracticeHistoryPrompt = false;
                this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practice-history/${this.practicingCategory.id}?tested=${this.testedId}`);
                return;
            }

            this.headerInfo = {
                groupNo: q.groupNo,
                quesNo: this.activeIndex + 1,
                quesType: q.quesType,
                score: this.isShowScore ? q.score : '',
                progress: `${(this.activeIndex + 1) / this.basicQuestions.length * 100}%`,
            };
        },
        changeQuestion() {
            this.tempHandedInfo = {};
            this.handedId = '';
            this.getNextQuestion();
        },
    },
}
