import WebSocket from '@/global/websocket.js'
import '@/global/markdown/md_preview.css'

// common
const Dialogue = resolve => require(["@/components/common/dialogue_client.vue"], resolve)
// components
const Header = resolve => require(["@/components/article/practicing/components/header.vue"], resolve)
const ReviewDescription = resolve => require(["@/components/article/practicing/write/_translation_review_description.vue"], resolve)

export default {
    props: {
        testedId: {
            type: String,
            default: '',
        },
        question: {
            type: Object,
            default: () => {},
        },
        tempHandedInfo: {
            type: Object,
            default: () => {},
        },
        handedId: {
            type: String,
            default: '',
        },
        headerInfo: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        Dialogue,
        Header,
        ReviewDescription,
    },
    data: function() {
        return {
            handinTextLengthLimit: { max: 60 },
            timeout: { reviewTime: 180 },
            pageUrl: location.href,

            translation: {},
            handinInfo: {
                text: '',
                isTextError: false,
                isTextDifferent: false,
                isTextExceedLength: false,
            },
            practiceTimerId: 0,
            practiceTime: 0,
            tempRecognizeId: '',
            reviewTimerId: 0,
            reviewTime: 0,
            isReviewing: false,
            isReviewTimeout: false,
            isReviewed: false,
            isReReviewed: false,
            websocket: new WebSocket('Review', process.env.VUE_APP_RECOGNIZE_WS_URL, this.getReviewedResult),

            isTranslationReady: false,
            isInitialized: false,
            isPostingApi: {
                handInAnswer: false,  // 送出作答
                getReviewedResult: false,  // 取得批改結果
                practiceDone: false,  // 單一題練習結束
            },
        }
    },
    created: function() {
        this.initTranslation();
        this.websocket.connect({
            action: 'register',
            data: { practiceTestedId: this.testedId },
        });
    },
    beforeDestroy: function() {
        this.websocket.close();
    },
    methods: {
        async initTranslation() {
            const q = this.question;
            const handedInfo = this.tempHandedInfo;
            const defaultData = {
                id: q.id,
                text: q.title.text,
                description: q.description,
                hint: q.tips,
            };
            const variableData = {
                practiceAnswer: handedInfo.answer_content ? handedInfo.answer_content : '',
                answer: '',
                comment: '',
                feedback: '',
            };
            this.translation = {...defaultData, ...variableData};
            this.handinInfo.text = this.translation.practiceAnswer;
            this.tempRecognizeId = this.handedId;

            await this.getReviewedResult(null);
            this.isInitialized = true;

            this.handinTextChangedHandler();

            this.practiceTime = 0;
            this.practiceTimerId = setInterval(() => this.practiceTime++, 1000);
        },

        handinTextChangedHandler() {
            this.handinInfo.isTextError = !!(!this.handinInfo.text.trim() || this.handinInfo.text.trim().split(/\s+/).length > this.handinTextLengthLimit.max);
            this.handinInfo.isTextDifferent = this.handinInfo.text.trim() !== this.translation.practiceAnswer.trim();
            this.handinInfo.isTextExceedLength = !!(this.handinInfo.text.trim().split(/\s+/).length > this.handinTextLengthLimit.max);
            this.$nextTick(() => this.$node.resizeTextarea(document.getElementById(`${this.translation.id}_answer`), true));
        },
        handInAnswer(isTempHandin) {
            clearInterval(this.practiceTimerId);
            this.isReviewing = !isTempHandin;

            const answer = {
                practiceTestedId: this.testedId,
                questionId: this.translation.id,
                answerOption: {
                    answerContent: this.handinInfo.text,
                    answerIds: [],
                    answerWords: [],
                    answerCompositions: [],
                },
                time: this.practiceTime,
                isTempHandin: isTempHandin,
            };

            let params = new FormData();
            params.append('answer', JSON.stringify(answer));

            if (isTempHandin) {
                this.isPostingApi.handInAnswer = true;
            } else {
                this.reviewTimerId = setInterval(this.reviewTimerHandler, 1000);
            }

            this.$httpRequest.post('/api/practice/handin', params)
                .then(response => {
                    this.isPostingApi.handInAnswer = false;

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            this.translation.practiceAnswer = this.handinInfo.text;
                            this.handinTextChangedHandler();

                            if (isTempHandin) return;

                            this.translation.answer = result.right_answer.right_answer_content[0];
                            this.translation.comment = result.comment ? result.comment.text : '';
                            this.tempRecognizeId = result.recognize_id;
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.handInAnswer = false;
                    clearInterval(this.reviewTimerId);
                    this.isReviewing = false;
                    console.error('Catched Error:', error);
                });
        },
        getReviewedResult(data) {
            // Api
            if (!data) {
                return new Promise((resolve, reject) => {
                    const params = {
                        recognizeId: this.tempRecognizeId,
                    };

                    this.isPostingApi.getReviewedResult = true;

                    this.$httpRequest.get('/api/practice/get_ai_result', params)
                        .then(response => {
                            this.isPostingApi.getReviewedResult = false;

                            if (response.data.state == 'OK') {
                                const result = response.data.result;

                                if (result) {
                                    if (this.isReviewing && result.msg) {
                                        this.$store.dispatch('common/setAlert', { status: 'danger', msg: result.msg });
                                        resolve();
                                        return;
                                    }
                                    if (result.is_done === null) {
                                        resolve();
                                        return;
                                    }

                                    this.isReviewing = false;
                                    this.isReviewTimeout = false;
                                    this.isReviewed = true;
                                    this.isReReviewed = result.retry > 0;
                                    this.translation.answer = result.answer_content[0];
                                    this.translation.comment = result.comment ? result.comment.text : '';
                                    this.translation.feedback = result.ai_result;
                                    resolve();
                                }
                            }
                        })
                        .catch(error => {
                            this.isPostingApi.getReviewedResult = false;
                            reject(error);
                        });
                });
            }
            // WebSocket
            else {
                // 註冊連線成功
                if (data.body === 'success') {
                    this.isTranslationReady = true;
                }

                // 收到辨識結果
                if (data.ptq_id) {
                    const recognizeId = data.ptq_id;
                    const recognizeMode = data.mode;

                    if (recognizeId !== this.tempRecognizeId) {
                        return;
                    }
                    if (recognizeMode !== 'ai_result') {
                        return;
                    }

                    const result = data.result.ai_result;
                    clearInterval(this.reviewTimerId);
                    this.isReviewing = false;
                    this.isReviewed = true;
                    this.translation.feedback = result;
                }
            }
        },
        reReviewAnswer() {
            this.isReReviewed = true;
            const lastFeedback = this.translation.feedback;
            this.translation.feedback = '';

            const params = {
                ptId: this.testedId,
                qId: this.translation.id,
            };

            this.$httpRequest.post('/api/practice/resend_ai_result', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });
                    }
                })
                .catch(error => {
                    this.isReReviewed = false;
                    this.translation.feedback = lastFeedback;
                    console.error('Catched Error:', error);
                });
        },
        reviewTimerHandler() {
            this.reviewTime++;
            if (this.isReviewing && (this.reviewTime === this.timeout.reviewTime)) {
                clearInterval(this.reviewTimerId);
                this.isReviewTimeout = true;
            }
        },
        showHintDialogue() {
            $('#hintDialogue').modal('show');
        },
        showCommentDialogue() {
            $('#commentDialogue').modal('show');
        },
        showReviewDescriptionDialogue() {
            $('#translationReviewDescriptionDialogue').modal('show');
        },
        changeQuestion() {
            this.websocket.close();

            const params = {
                practiceTestedId: this.testedId,
                questionIds: [this.translation.id],
            };

            this.isPostingApi.practiceDone = true;

            this.$httpRequest.post('/api/practice/ai_question_done', params)
                .then(response => {
                    this.isPostingApi.practiceDone = false;

                    if (response.data.state == 'OK') {
                        this.$emit('changeQuestion');
                    }
                })
                .catch(error => {
                    this.isPostingApi.practiceDone = false;
                    console.error('Catched Error:', error);
                });
        },
    },
}
