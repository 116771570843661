import draggable from "vuedraggable"
// common
import Dialogue from "@/components/common/dialogue.vue"
// video player
import DPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'
// components
const MarkdownEditor = resolve => require(["@/components/common/markdown/editor.vue"], resolve)
const MarkdownPreviewer = resolve => require(["@/components/common/markdown/previewer.vue"], resolve)

export default {
    props: ['articleBasicInfo'],
    components: {
        draggable,
        Dialogue,
        DPlayer,
        MarkdownEditor,
        MarkdownPreviewer
    },
    data: function() {
        return {
            activeTab: '',
            articleInfo : {
                type: this.articleBasicInfo.type
            },
            oldArticleInfo: null,
            uploadErrorMsg: {
                file: null,
            },

            isSetDataReady: {
                getArticleInfo: false,  // 取得文章資訊
                getImportStatus: false // 匯入中需要disable[確認]按鈕
            },

            // 用於暫存文章補充路徑(畫面右上角)
            articleSupplement: {
                path: '',
                name: '',
                file: ''
            },
            // 用於暫存影片路徑
            media: {
                path: '',
                name: '',
                file: ''
            },
            options: {},
            // 影片上傳或刪除提示文字
            mediaLoadingText: '',
            mediaLoadingShow: false,

            // 錯誤訊息
            contentErrorMsg: '',

            // 段落
            paragraphList: null,
            oldParagraphList: null,
            paragraphIndex: null,
            paragraphOldIndex: null,
            paragraphNewIndex: null,
            sortParagraphList: null, // temp array in pop up sort

            // 刪除資料存放的各陣列
            deleteParagraphIds: [],
            deleteSentenceIds: [],
            deleteCommentFileIds: [],
            deleteMaterialIds: [],
            deleteContentIds: [],
            deleteExampleIds: [],

            // 單字
            wordBlockList: null,
            oldWordBlockList: null,
            wordBlockIndex: null,
            wordBlockOldIndex: null,
            wordBlockNewIndex: null,
            sortWordBlockList: null, // temp array in pop up sort

            // 段落或內容是否排序flag
            isSort: false,

            // 非英文編輯區塊
            mkContent: '',
            oldMkContent: null,
            // 補充資料編輯區塊，放自訂學習單連結使用
            mkSupplement: '',
            oldMkSupplement: null,
            // 有聲文本
            mergeAudioPath: '',
            lastmodTime: '',
            voiceOfArticleList: [],
            oldVoiceOfArticleList: null,
            voiceOfArticleIsChange: false,
            audio: new Audio(),
        }
    },
    watch: {
        activeTab(newValue) {
            if (newValue == 'voiceTotext') {
                this.$nextTick(() => this.$node.resizeTextarea());
            }
        }
    },
    computed: {
        editArticleContentTabList() {
            let tabs = [];

            if (this.articleBasicInfo.type == 0) {
                tabs = [
                    {
                        value: 'media',
                        text: '影音媒體'
                    },
                    {
                        value: 'content',
                        text: '段落內容'
                    },
                    {
                        value: 'englishSupplement',
                        text: '英文補給'
                    },
                    {
                        value: 'supplement',
                        text: '補充資料'
                    },
                ]
            }

            if (this.articleBasicInfo.type == 1) {
                tabs = [
                    {
                        value: 'contentEdit',
                        text: '內容編輯'
                    },
                    {
                        value: 'supplement',
                        text: '補充資料'
                    },
                    {
                        value: 'voiceTotext',
                        text: '有聲文本'
                    },
                    
                ]
            }
            return tabs;
        },
        paragraphTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "move",
                    itemText: "移動",
                    ownClass: "sort-move",
                    isShow: true
                },
                {
                    itemName: "no",
                    itemText: "段落編號",
                    ownClass: "sort-no",
                    isShow: true
                },
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        wordBlackTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "move",
                    itemText: "移動",
                    ownClass: "sort-move",
                    isShow: true
                },
                {
                    itemName: "no",
                    itemText: "區塊編號",
                    ownClass: "sort-no",
                    isShow: true
                },
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        blockTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '單字'
                },
                {
                    value: '1',
                    text: '文句'
                }
            ]
            return opts;
        },
        speechTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '名詞'
                },
                {
                    value: '1',
                    text: '代名詞'
                },
                {
                    value: '2',
                    text: '動詞'
                },
                {
                    value: '3',
                    text: '助動詞'
                },
                {
                    value: '4',
                    text: '形容詞'
                },
                {
                    value: '5',
                    text: '副詞'
                },
                {
                    value: '6',
                    text: '連接詞'
                },
                {
                    value: '7',
                    text: '介繫詞'
                },
                {
                    value: '8',
                    text: '冠詞'
                },
                {
                    value: '9',
                    text: '感嘆詞'
                },
            ]
            return opts;
        },
        voiceIntervalList() {
            let lists = [];

                lists = [
                    {
                        value: 250,
                        text: '250ms'
                    },
                    {
                        value: 500,
                        text: '500ms'
                    },
                    {
                        value: 750,
                        text: '750ms'
                    },
                    {
                        value: 1000,
                        text: '1000ms'
                    },
                    {
                        value: 1500,
                        text: '1500ms'
                    },
                    {
                        value: 2000,
                        text: '2000ms'
                    },
                ]
            return lists;
        },
        updateArticleData() {
            if (!this.isSetDataReady.getArticleInfo) {
                return;
            }

            let updateData = {};

            if (this.articleBasicInfo.type == 0) {

                updateData = {
                    saveType: 'content', // 指定儲存類型
                };

                let articleInfoChange = {},
                    updateInfo = {
                        paragraphSentence: {},
                        additionalMaterials: {}
                    },
                    isTitleEmpty = false,
                    isTimeErr = false,
                    isNameEmpty = false,
                    isWordEmpty = false,
                    regexStr = /^[0-9][0-9]:[0-9][0-9]:[0-9][0-9]$/;

                this.contentErrorMsg = '';
                this.articleInfo.inputErr = false;

                let excludeKeys = ['inputErr'];  // 排除檔案
                articleInfoChange = this.$_.omitBy(this.$_.omit(this.articleInfo, excludeKeys), (v, k) => {
                    return this.oldArticleInfo[k] === v;
                });

                // 檢查文章[時間點]輸入格式是否正確
                if ((this.articleInfo.startTime && !this.articleInfo.startTime.match(regexStr))
                    || (this.articleInfo.endTime && !this.articleInfo.endTime.match(regexStr))) {
                    this.contentErrorMsg = '輸入格式有錯';
                    this.articleInfo.inputErr = true;
                    isTimeErr = true;
                }

                // 比對[段落內容]區塊資料，迴圈總共二層
                this.oldParagraphList.forEach((el, index) => {
                    let sort = {
                            id : el.ap_id,
                            sentences : []
                        };
                    el.contents.forEach((sub, subIndex) => {
                        let seconeIsChange = false, // 資料有改變才需要push進updateInfo
                            contents = {
                                id: sub.apc_id,
                                type: sub.type
                            };

                        if (sub.type == 'sentence') { // 詞句
                            // 檢查段落內容[英文]是否為空值
                            if (!this.paragraphList[index].contents[subIndex].english) {
                                isTitleEmpty = true;
                                return false;
                            }
                            // 檢查段落內容[中文]是否為空值
                            if (!this.paragraphList[index].contents[subIndex].chinese) {
                                isTitleEmpty = true;
                                return false;
                            }
                            if (this.paragraphList[index].contents[subIndex].english != sub.english) {
                                seconeIsChange = true;
                                contents.english = this.paragraphList[index].contents[subIndex].english;
                            }
                            if (this.paragraphList[index].contents[subIndex].chinese != sub.chinese) {
                                seconeIsChange = true;
                                contents.chinese = this.paragraphList[index].contents[subIndex].chinese;
                            }
                            if (this.paragraphList[index].contents[subIndex].comment != sub.comment) {
                                seconeIsChange = true;
                                contents.comment = this.paragraphList[index].contents[subIndex].comment;
                            }

                            this.paragraphList[index].contents[subIndex].inputErr = false; // 讓boolean值先恢復
                            if (this.paragraphList[index].contents[subIndex].comment_time_point_start != sub.comment_time_point_start) {
                                seconeIsChange = true;
                                contents.startTime = this.paragraphList[index].contents[subIndex].comment_time_point_start;

                                // 檢查段落內容[時間點]輸入格式是否正確
                                if (this.paragraphList[index].contents[subIndex].comment_time_point_start
                                    && !this.paragraphList[index].contents[subIndex].comment_time_point_start.match(regexStr)) {
                                    this.contentErrorMsg = '輸入格式有錯';
                                    this.paragraphList[index].contents[subIndex].inputErr = true; // 有錯boolean值為true
                                    isTimeErr = true;
                                }
                            }

                            if (this.paragraphList[index].contents[subIndex].comment_time_point_end != sub.comment_time_point_end) {
                                seconeIsChange = true;
                                contents.endTime = this.paragraphList[index].contents[subIndex].comment_time_point_end;

                                // 檢查段落內容[時間點]輸入格式是否正確
                                if (this.paragraphList[index].contents[subIndex].comment_time_point_end
                                    && !this.paragraphList[index].contents[subIndex].comment_time_point_end.match(regexStr)) {
                                    this.contentErrorMsg = '輸入格式有錯';
                                    this.paragraphList[index].contents[subIndex].inputErr = true; // 有錯boolean值為true
                                    isTimeErr = true;
                                }
                            }

                            // 通常詞句圖片一改變就會把cover.path改變成base64的路徑
                            // 如果資料為null不需比較差異，因為null的就是刪除
                            if (this.paragraphList[index].contents[subIndex].cover.path != sub.cover.path
                                && this.paragraphList[index].contents[subIndex].cover.path != null) {
                                seconeIsChange = true;
                                if (!contents.images) {
                                    contents.images = [];
                                }
                                contents.images[0] = null;
                            }
                        }
                        // 圖片區塊
                        if (sub.type == 'image') {
                            if (this.paragraphList[index].contents[subIndex].comment != sub.comment) {
                                seconeIsChange = true;
                                contents.comment = this.paragraphList[index].contents[subIndex].comment;
                            }

                            if (this.paragraphList[index].contents[subIndex].cover.path != sub.cover.path) {
                                seconeIsChange = true;
                            }
                        }

                        // 有改變(seconeIsChange)才會把資料push進update陣列中
                        if (seconeIsChange) {
                            if (!updateInfo.paragraphSentence.update) {
                                updateInfo.paragraphSentence.update = [];
                            }
                            updateInfo.paragraphSentence.update.push(contents);
                        }
                        sort.sentences.push(sub.apc_id)
                    });

                    if (this.isSort) {
                        if (!updateInfo.paragraphSentence.sort) {
                            updateInfo.paragraphSentence.sort = [];
                        }
                        updateInfo.paragraphSentence.sort.push(sort);
                        updateData.updateInfo = updateInfo;
                    }
                });

                // 比對[英文補給]區塊資料，迴圈總共三層
                this.oldWordBlockList.forEach((el, index) => {
                    let firstIsChange = false, // boolean值 查看下方資料是否有改變
                        wordBlock = {
                            id: el.aam_id
                        },
                        sort = {
                            id : el.aam_id,
                            contents : []
                        };

                    // 檢查英文補給[區塊名稱]是否為空值
                    if (!this.wordBlockList[index].name) {
                        isNameEmpty = true;
                    }

                    if(this.wordBlockList[index].name != el.name) {
                        firstIsChange = true;
                        wordBlock.name = this.wordBlockList[index].name;
                    }
                    if(this.wordBlockList[index].type != el.type) {
                        firstIsChange = true;
                        wordBlock.type = this.wordBlockList[index].type;
                    }

                    this.wordBlockList[index].inputErr = false; // 讓boolean值先恢復

                    if(this.wordBlockList[index].comment_time_point_start != el.comment_time_point_start) {
                        firstIsChange = true;
                        wordBlock.startTime = this.wordBlockList[index].comment_time_point_start;

                        // 檢查英文補給[時間點]輸入格式是否正確
                        if (this.wordBlockList[index].comment_time_point_start
                            && !this.wordBlockList[index].comment_time_point_start.match(regexStr)) {
                            this.contentErrorMsg = '輸入格式有錯';
                            this.wordBlockList[index].inputErr = true; // 有錯boolean值為true
                            isTimeErr = true;
                        }
                    }

                    if(this.wordBlockList[index].comment_time_point_end != el.comment_time_point_end) {
                        firstIsChange = true;
                        wordBlock.endTime = this.wordBlockList[index].comment_time_point_end;

                        // 檢查英文補給[時間點]輸入格式是否正確
                        if (this.wordBlockList[index].comment_time_point_end
                            && !this.wordBlockList[index].comment_time_point_end.match(regexStr)) {
                            this.contentErrorMsg = '輸入格式有錯';
                            this.wordBlockList[index].inputErr = true; // 有錯boolean值為true
                            isTimeErr = true;
                        }
                    }

                    if (firstIsChange) {
                        if (!updateInfo.additionalMaterials.update) { // 沒有update這個key須建立
                            updateInfo.additionalMaterials.update = [];
                        }
                        // 英文補給跟段落不是用push進陣列，而是用index
                        updateInfo.additionalMaterials.update[index] = wordBlock;
                    }

                    el.contents.forEach((cEl, cIndex) => {
                        sort.contents.push({
                            id: cEl.aamc_id
                        });
                        let seconeIsChange = false, // boolean值 查看下方資料是否有改變
                            contents = {
                                id: cEl.aamc_id
                            };

                        // 檢查英文補給[單字]或[文句]是否為空值
                        if (!this.wordBlockList[index].contents[cIndex].word_sentence) {
                            isWordEmpty = true;
                        }

                        if (this.wordBlockList[index].contents[cIndex].word_sentence != cEl.word_sentence) {
                            seconeIsChange = true;
                            contents.wordSentence = this.wordBlockList[index].contents[cIndex].word_sentence;
                        }
                        if (this.wordBlockList[index].type == 0 &&
                            this.wordBlockList[index].contents[cIndex].speech_type != cEl.speech_type) {
                            seconeIsChange = true;
                            contents.speechType = this.wordBlockList[index].contents[cIndex].speech_type;
                        }
                        if (this.wordBlockList[index].contents[cIndex].chinese_explain != cEl.chinese_explain) {
                            seconeIsChange = true;
                            contents.chineseExplain = this.wordBlockList[index].contents[cIndex].chinese_explain;
                        }
                        if (this.wordBlockList[index].contents[cIndex].comment != cEl.comment) {
                            seconeIsChange = true;
                            contents.comment = this.wordBlockList[index].contents[cIndex].comment;
                        }
                        if (seconeIsChange) {
                            if (updateInfo.additionalMaterials.update) { // 查看第一層是否有建立資料
                                if (!updateInfo.additionalMaterials.update[index]) { // 第一層有可能沒有contents，沒有就要建
                                    wordBlock.contents = [];
                                    wordBlock.contents[cIndex] = contents;
                                    updateInfo.additionalMaterials.update[index] = wordBlock;
                                }

                                if (updateInfo.additionalMaterials.update[index].contents) { // 查看第二層contents陣列有沒有建立
                                    updateInfo.additionalMaterials.update[index].contents[cIndex] = contents;
                                }
                                else {
                                    updateInfo.additionalMaterials.update[index].contents = [];
                                    updateInfo.additionalMaterials.update[index].contents[cIndex] = contents;
                                }
                            }
                            else {
                                // 沒有資料的話，從第一層建資料
                                wordBlock.contents = [];
                                wordBlock.contents[cIndex] = contents;
                                if (!updateInfo.additionalMaterials.update) {
                                    updateInfo.additionalMaterials.update = [];
                                }
                                updateInfo.additionalMaterials.update[index] = wordBlock;
                            }
                        }

                        // 因為在排序的過程中，有可能新舊example的數量會不同，陣列長度必須一樣才能比對
                        if (this.wordBlockList[index].contents[cIndex].example.length == cEl.example.length) {
                            cEl.example.forEach((eEl, eIndex) => {
                                if (!sort.contents.example) {
                                    sort.contents.example = [];
                                    sort.contents.example.push(cEl.aamce_id);
                                }
                                let thirdIsChange = false,
                                    example = {
                                        id: eEl.aamce_id
                                    };

                                if (this.wordBlockList[index].contents[cIndex].example[eIndex].example_sentence != eEl.example_sentence) {
                                    thirdIsChange = true;
                                    example.exampleSentence = this.wordBlockList[index].contents[cIndex].example[eIndex].example_sentence;
                                }
                                if (this.wordBlockList[index].contents[cIndex].example[eIndex].sentence_explain != eEl.sentence_explain) {
                                    thirdIsChange = true;
                                    example.sentenceExplain = this.wordBlockList[index].contents[cIndex].example[eIndex].sentence_explain;
                                }
                                if (thirdIsChange) {
                                    if (updateInfo.additionalMaterials.update) { // 查看第一層是否有建立資料
                                        if (!updateInfo.additionalMaterials.update[index].contents) { // 查看第二層contents陣列有沒有建立
                                            updateInfo.additionalMaterials.update[index].contents = [];
                                        }
                                        if (updateInfo.additionalMaterials.update[index].contents[cIndex]) { // 查看第二層contents陣列有沒有內容
                                            updateInfo.additionalMaterials.update[index].contents[cIndex] = contents;
                                        }
                                        else {
                                            updateInfo.additionalMaterials.update[index].contents[cIndex] = {};
                                            updateInfo.additionalMaterials.update[index].contents[cIndex] = contents;
                                        }
                                        if (updateInfo.additionalMaterials.update[index].contents[cIndex].example) { // 查看第三層example陣列有沒有建立
                                            updateInfo.additionalMaterials.update[index].contents[cIndex].example[eIndex] = example;
                                        }
                                        else {
                                            updateInfo.additionalMaterials.update[index].contents[cIndex].example = [];
                                            updateInfo.additionalMaterials.update[index].contents[cIndex].example[eIndex] = example;
                                        }
                                    }
                                    else {
                                        // 沒有資料的話，從第一層建資料
                                        wordBlock.contents = [];
                                        wordBlock.contents[cIndex] = contents;
                                        if (!updateInfo.additionalMaterials.update) {
                                            updateInfo.additionalMaterials.update = [];
                                        }
                                        updateInfo.additionalMaterials.update[index] = wordBlock;
                                        updateInfo.additionalMaterials.update[index].contents[cIndex].example = [];
                                        updateInfo.additionalMaterials.update[index].contents[cIndex].example[eIndex] = example;
                                    }
                                }
                            });
                        }
                    });

                    if (this.isSort) {
                        if (!updateInfo.additionalMaterials.sort) {
                            updateInfo.additionalMaterials.sort = [];
                        }
                        updateInfo.additionalMaterials.sort.push(sort);
                        updateData.updateInfo = updateInfo;
                    }
                });

                // [注意] 刪除不會重新sort
                // 檢查段落有沒有刪除
                if (this.deleteParagraphIds.length != 0) {
                    updateInfo.paragraphSentence.deleteParagraphIds = this.deleteParagraphIds;
                    updateData.updateInfo = updateInfo;
                }
                // 檢查句子有沒有刪除
                if (this.deleteSentenceIds.length != 0) {
                    updateInfo.paragraphSentence.deleteSentenceIds = this.deleteSentenceIds;
                    updateData.updateInfo = updateInfo;
                }
                // 檢查有沒有詞句圖檔刪除
                if (this.deleteCommentFileIds.length != 0) {
                    updateInfo.paragraphSentence.deleteCommentFileIds = this.deleteCommentFileIds;
                    updateData.updateInfo = updateInfo;
                }
                // 檢查有沒有區塊刪除
                if (this.deleteMaterialIds.length != 0) {
                    updateInfo.additionalMaterials.deleteMaterialIds = this.deleteMaterialIds;
                    updateData.updateInfo = updateInfo;
                }
                // 檢查有沒有單字刪除
                if (this.deleteContentIds.length != 0) {
                    updateInfo.additionalMaterials.deleteContentIds = this.deleteContentIds;
                    updateData.updateInfo = updateInfo;
                }
                // 檢查有沒有例句刪除
                if (this.deleteExampleIds.length != 0) {
                    updateInfo.additionalMaterials.deleteExampleIds = this.deleteExampleIds;
                    updateData.updateInfo = updateInfo;
                }

                if (this.$_.has(articleInfoChange, 'comment')
                    || this.$_.has(articleInfoChange, 'startTime')
                    || this.$_.has(articleInfoChange, 'endTime')
                    || this.$_.has(updateInfo.paragraphSentence, 'update')
                    || this.$_.has(updateInfo.additionalMaterials, 'update')) {
                    updateData.updateInfo = Object.assign(updateInfo, articleInfoChange); // 把上面兩個物件內容合成一個

                    if (this.$_.has(updateInfo.paragraphSentence, 'update')) {
                        updateData.paragraphList = this.paragraphList; // 因為需要圖片檔案，所以需要帶[段落內容]的所有資料過去
                        updateData.oldParagraphList = this.oldParagraphList; // [段落內容]舊資料也需要
                    }
                }

                // 如果輸入格式有錯，儲存紐disable
                if (isTitleEmpty || isTimeErr || isNameEmpty || isWordEmpty) {
                    updateData = {
                        saveType: 'content'
                    };
                }
            }

            if (this.articleBasicInfo.type == 1) {

                // content marketdown
                if (this.mkContent != this.oldMkContent) {
                    updateData = {
                        saveType: 'mkContent',
                        content : this.mkContent
                    }
                }
            }

            // content marketdown
            if (this.mkSupplement != this.oldMkSupplement) {
                updateData = {
                    saveType: 'mkSupplement',
                    content : this.mkSupplement
                }
            }

            if (this.articleBasicInfo.type == 1) {
                // voice to text
                let sentenceModified = [];
                let isPush = false;
                
                this.voiceOfArticleList.forEach((el, index) =>  {
                    if (el.text != this.oldVoiceOfArticleList[index].text ||
                        el.voiceInterval != this.oldVoiceOfArticleList[index].voiceInterval || 
                        this.voiceOfArticleIsChange)
                    {
                        // 檢查是否有改動資料，任何一筆改動都要傳全部資料
                        isPush = true;
                    }
                })

                if (isPush) {
                    this.voiceOfArticleList.forEach((el, index) =>  {
                        // 不論是新增、修改要傳到後端，刪除即不會有任何資料傳到後端
                        sentenceModified.push({
                            id: el.id != '' ? el.id : null,
                            text: el.text,
                            pre_interval: index == 0 ? 0 : el.voiceInterval // 第一筆間隔永遠為0
                        });
                    })
                }

                if (sentenceModified.length > 0) {
                    updateData = {
                        saveType: 'textToVoice',
                        content : sentenceModified
                    }
                }

                // 若刪除到陣列為空時
                if (this.voiceOfArticleIsChange && this.voiceOfArticleList.length == 0) {
                    updateData = {
                        saveType: 'textToVoice',
                        content : {}
                    }
                }
            }
            return updateData;
        },
        isEditable() {
            // 是否可以編輯文章資料項目, 條件如下:
            // 1. 文章狀態為"編輯中"
            // 2. 使用者權限為 admin 時, "待審核"狀態還可編輯以上項目
            return this.articleBasicInfo.state == '0' ||
                    (this.$store.state.common.userInfo.permission == 'admin' && this.articleBasicInfo.state == '1');
        }
    },
    created: function() {
        this.initialize();
    },
    methods: {
        initialize() {
            let refreshArticleInfo = () => {
                this.$parent.isFinishUpdatingData = false;
                this.isSetDataReady.getArticleInfo = false;
                this.oldArticleInfo = null;
                this.isSort = false; // 排序flag

                this.getArticleInfo()
                    .then(() => {
                        this.isSetDataReady.getArticleInfo = true;
                        this.activeTab = this.articleBasicInfo.type == 0 ? 'media' : 'contentEdit';
                        // 監聽編輯內容是否更動
                        this.$watch('updateArticleData', newValue => {
                            this.$store.commit('adminArticle/setUpdateArticleData', newValue);
                            this.$store.commit('adminArticle/setIsShowNotSaveArticlePrompt', !this.$_.isEmpty(this.$_.omit(newValue, ['saveType'])));
                        });
                        // 編輯文章解說，需更新vuex的值
                        this.$watch('articleInfo.comment', newValue => this.$store.commit('adminArticle/changeArticleComment', newValue));
                        // 編輯文章時間，需更新vuex的值
                        this.$watch('articleInfo.startTime', newValue => this.$store.commit('adminArticle/changeArticleStartTime', this.timeToSeconds(newValue)));
                        this.$watch('articleInfo.endTime', newValue => this.$store.commit('adminArticle/changeArticleEndTime', this.timeToSeconds(newValue)));
                    })
                    .catch(error => console.log('catched error: ' + error));
            }

            refreshArticleInfo();

            // 當成功更新文章資訊後, 則重新取得文章資訊
            this.$watch('$parent.isFinishUpdatingData', newValue => {
                if (newValue) {
                    refreshArticleInfo();
                }
            });
        },

        getArticleInfo() {
            return new Promise((resolve, reject) => {
                let params = {
                    articleId: this.$route.params.id
                }

                this.$httpRequest.get('/admin_api/article/get_article_content', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (this.articleBasicInfo.type == 0) {
                                    this.articleInfo = {
                                        // 基本資料
                                        type: this.articleBasicInfo.type,
                                        name: this.articleBasicInfo.name,
                                        subjectName: this.articleBasicInfo.subName,
                                        comment: this.articleBasicInfo.comment,
                                        startTime: this.secondsToTime(this.articleBasicInfo.commentTimePointStart),
                                        endTime: this.secondsToTime(this.articleBasicInfo.commentTimePointEnd),
                                        inputErr: false,
                                    }

                                    this.media.path = result.media ? result.media.file_name : '';

                                    this.options = {
                                        autoplay: false,
                                        lang: 'zh-tw',
                                        preload: 'auto',
                                        video: {
                                            type: "auto",
                                            url: this.media.path,
                                            defaultQuality: 0
                                        }
                                    }

                                    if (this.articleInfo) {
                                        // 暫存原本的文章資料, 以用來判斷是否enable儲存按鈕
                                        this.oldArticleInfo = this.$_.cloneDeep(this.articleInfo);
                                    }

                                    this.paragraphList = result.paragraph_contents;

                                    // 補上v-model所需參數，給每個參數做編號
                                    let v = this;
                                    this.paragraphList.forEach((el, index) => {
                                        let idList = {
                                            ap_id : el.ap_id,
                                            contents : []
                                        }
                                        el.contents.forEach((sub, subIndex) => {
                                            idList.contents.push({ apc_id: sub.apc_id});
                                            if (sub.type == 'sentence') {
                                                v.$set(this.paragraphList[index].contents[subIndex], 'cover', sub.comment_files.length != 0 ? { path: sub.comment_files[0].comment_file_name, name: '', file: '', apccf_id: sub.comment_files[0].apccf_id } : { path: '', name: '', file: '', id: ''});
                                            }
                                            else {
                                                v.$set(this.paragraphList[index].contents[subIndex], 'cover', sub.file_name ? { path: sub.file_name, name: '', file: ''} : { path: '', name: '', file: ''});
                                            }
                                            sub.comment_time_point_start = this.secondsToTime(sub.comment_time_point_start);
                                            sub.comment_time_point_end = this.secondsToTime(sub.comment_time_point_end);
                                            v.$set(this.paragraphList[index].contents[subIndex], 'inputErr', false);
                                        });
                                    });

                                    if (this.paragraphList) {
                                        // 暫存原本的段落資料, 以用來判斷是否enable儲存按鈕
                                        this.oldParagraphList = this.$_.cloneDeep(this.paragraphList);
                                    }

                                    this.wordBlockList = result.additional_materials;

                                    this.wordBlockList.forEach((el, index) => {
                                        let idList = {
                                            aam_id : el.aam_id,
                                            contents : []
                                        }
                                        el.comment_time_point_start = this.secondsToTime(el.comment_time_point_start);
                                        el.comment_time_point_end = this.secondsToTime(el.comment_time_point_end);
                                        v.$set(this.wordBlockList[index], 'inputErr', false);

                                        el.contents.forEach((cEl) => {
                                            let contentsList = {
                                                aamc_id : cEl.aamc_id,
                                                example : [],
                                            }
                                            cEl.example.forEach((eEl) => {
                                                let exampleList = {
                                                    aamce_id : eEl.aamce_id
                                                }
                                                contentsList.example.push(exampleList);
                                            });
                                            idList.contents.push(contentsList);
                                        });
                                    });

                                    if (this.wordBlockList) {
                                        // 暫存原本的單字區塊資料, 以用來判斷是否enable儲存按鈕
                                        this.oldWordBlockList = this.$_.cloneDeep(this.wordBlockList);
                                    }

                                    // 檢查所有刪除的陣列是否有值，有的話清空
                                    // 段落
                                    if (this.deleteParagraphIds.length != 0) {
                                        this.deleteParagraphIds = [];
                                    }
                                    // 句子
                                    if (this.deleteSentenceIds.length != 0) {
                                        this.deleteSentenceIds = [];
                                    }
                                    // 詞句圖檔
                                    if (this.deleteCommentFileIds.length != 0) {
                                        this.deleteCommentFileIds = [];
                                    }
                                    // 區塊
                                    if (this.deleteMaterialIds.length != 0) {
                                        this.deleteMaterialIds = [];
                                    }
                                    // 單字
                                    if (this.deleteContentIds.length != 0) {
                                        this.deleteContentIds = [];
                                    }
                                    // 例句
                                    if (this.deleteExampleIds.length != 0) {
                                        this.deleteExampleIds = [];
                                    }

                                }

                                if (this.articleBasicInfo.type == 1) {
                                    this.articleInfo.type = this.articleBasicInfo.type;
                                    this.mkContent = result.content;
                                    // 暫存原本的文章資料, 以用來判斷是否enable儲存按鈕
                                    this.oldMkContent = this.$_.cloneDeep(this.mkContent);
                                }

                                this.mkSupplement = result.supplement_md;
                                // 暫存原本的文章資料, 以用來判斷是否enable儲存按鈕
                                this.oldMkSupplement = this.$_.cloneDeep(this.mkSupplement);

                                // 初始化各參數，以防全部刪除，參數還存在
                                this.mergeAudioPath = '';
                                this.lastmodTime = '';
                                this.voiceOfArticleIsChange = false;
                                this.voiceOfArticleList = [];
                                if (!result.sentences || result.sentences.length == 0) {
                                    this.voiceOfArticleList.push({
                                        voiceInterval: 0, // 第一筆不會有區間，直接設0
                                        text: ''
                                    });
                                }
                                else {
                                    this.mergeAudioPath = result.content_file_name;
                                    this.lastmodTime = this.$util.unixTimestampToDatetime(result.content_lastmod, this.$util.getBrowserCurrentTimeZone());
                                    for(let item of Object.values(result.sentences)) {
                                        this.voiceOfArticleList.push({
                                            id: item.id,
                                            audioPath: item.file_name,
                                            voiceInterval: item.pre_interval,
                                            text: item.text
                                        });
                                    }
                                }

                                this.oldVoiceOfArticleList = this.$_.cloneDeep(this.voiceOfArticleList);

                                resolve();
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                        reject("admin/article/_edit.js: 'getArticleInfo' get error");
                    });
            });
        },
        secondsToTime(seconds) {
            if (seconds == 0) {
                return '00:00:00';
            }

            if (seconds == null) {
                return;
            }
            let daySec = 24 * 60 * 60;
            let hourSec = 60 * 60;
            let minuteSec = 60;
            let dd = Math.floor(seconds / daySec);
            let hh = Math.floor((seconds % daySec) / hourSec);
            if ( hh < 10 ) {
                hh = "0" + hh;
            }
            let mm = Math.floor((seconds % hourSec) / minuteSec);
            if ( mm < 10 ) {
                mm = "0" + mm;
            }
            let ss = seconds%minuteSec;
            if ( ss < 10 ) {
                ss = "0" + ss;
            }

            if (dd > 0) {
                return dd + ":" + hh + ":" + mm + ":" + ss;
            }
            else if (hh > 0) {
                return hh + ":" + mm + ":" + ss;
            }
            else if (mm > 0){
                return "00:" + mm + ":" + ss;
            }
            else {
                return "00:" + "00:" + ss;
            }
        },
        timeToSeconds(time) {
            let timeArr = time.split(':'),
                seconds = 0;

            if(timeArr[2]) {
                seconds += parseInt(timeArr[2]);
            }

            if(timeArr[1]) {
                seconds += parseInt(timeArr[1]) * 60;
            }

            if(timeArr[0]) {
                seconds += parseInt(timeArr[0]) * 60 * 60;
            }

            return seconds;
        },
        showImportSupplementDialogue() { // 右上角文章匯入
            if (!this.$_.isEmpty(this.$_.omit(this.updateArticleData, ['saveType']))) {
                this.$store.dispatch('common/setAlert', { msg: '請先把資料儲存後才能匯入', status: 'danger' });
                return
            }
            $('#showImportSupplementDialogue').modal('show');
        },
        importArticleSupplement() {
            let params = new FormData();
            params.append('articleId', this.$route.params.id);
            params.append('upload', this.articleSupplement.file);

            this.isSetDataReady.getImportStatus = true;
            this.$httpRequest.post('/admin_api/article/insert_content_import_excel', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.isSetDataReady.getImportStatus = false;
                        this.articleSupplement = {
                            path: '',
                            name: '',
                            file: ''
                        };
                        $('#showImportSupplementDialogue').modal('hide');
                        this.initialize();
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        addArticleSupplementExcel() {
            let v = this;

            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs.articleSupplement.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['xlsx', 'xls'];
            let perFileLimitSize = 209715200;  // 200mb

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = function() {
                    let dataURL = reader.result;
                    v.articleSupplement.path = dataURL;
                }

                this.articleSupplement.name = tempUploadFile.name;
                this.articleSupplement.file = tempUploadFile;
            }

            this.$refs.articleSupplement.value = null;
        },
        showAddVideoDialogue() {
            this.mediaLoadingText = '';
            $('#showAddVideoDialogue').modal('show');
        },
        updateMedia(type) {
            // 關閉上傳視窗
            $('#showAddVideoDialogue').modal('hide');

            let params = new FormData();
            params.append('articleId', this.$route.params.id);
            params.append('media', this.media.file);

            this.mediaLoadingShow = true;
            this.mediaLoadingText = '檔案上傳中...';
            if(type == 'delete') {
                params.append('deleteType', 1);
                this.mediaLoadingText = '檔案刪除中...';
            }

            this.$httpRequest.post('/admin_api/article/upload_media', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if(type == 'delete') {
                            this.media = {
                                    name: '',
                                    path: '',
                                    file: ''
                                };
                        }
                        else {
                            if (response.data.result) {
                                this.media.path = response.data.result;
                                this.options = {
                                    autoplay: false,
                                    lang: 'zh-tw',
                                    preload: 'auto',
                                    video: {
                                        type: "auto",
                                        url: this.media.path,
                                        defaultQuality: 0
                                    }
                                }
                            }
                        }
                        this.mediaLoadingShow = false;
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        uploadVideoFile() {
            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs.uploadVideo.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['mp4', 'mp3'];
            let perFileLimitSize = 2097152000;  // 2GB

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {
                this.media = this.$_.mapValues(this.media, () => null);
                this.media.name = tempUploadFile.name;
                this.media.file = tempUploadFile;
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過2GB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs.uploadVideo.value = null;
        },
        addParagraph(index, no) {
            let params = {
                    articleId: this.$route.params.id,
                    no: parseInt(no)+1
                };

            this.$httpRequest.post('/admin_api/article/create_paragraph', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let apId = response.data.result.ap_id,
                                apcId = response.data.result.apc_id,
                                obj = {
                                    ap_id: apId,
                                    ap_no: params.no,
                                    contents: [
                                        {
                                            apc_id: apcId,
                                            apc_no: 1,
                                            type: 'sentence',
                                            english: '',
                                            chinese: '',
                                            comment: '',
                                            comment_files: [],
                                            cover: {
                                                name: '',
                                                path: '',
                                                file: ''
                                            },
                                            comment_time_point_start: null,
                                            comment_time_point_end: null,
                                            inputErr: false
                                        }
                                    ]
                                };

                            this.paragraphList.splice(index+1, 0, obj);
                            this.oldParagraphList.splice(index+1, 0, obj); // 因為[新增段落]是直接更新資料庫，所以比對資料也要新增
                            this.paragraphList.forEach((el, key) => { // 新增項目之後的項目no都要+1
                                if (key > index+1) {
                                    el.ap_no = parseInt(el.ap_no)+1;
                                    this.oldParagraphList[key].ap_no = parseInt(this.oldParagraphList[key].ap_no)+1;
                                }
                            });
                            this.oldParagraphList = this.$_.cloneDeep(this.oldParagraphList); // 必須要再複製一次，不然新舊資料會一樣，到updateArticleData會比對不出來
                            // let focus new paragraph's input
                            this.$nextTick(() => this.$refs['english'+apcId][0].focus());
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        addContent(type, paragraphId, index, cI, no) { // cI是指content index
            let params = {
                    articleId: this.$route.params.id,
                    type: type,
                    paragraphId: paragraphId,
                    no: parseInt(no)+1
                };

            this.$httpRequest.post('/admin_api/article/create_paragraph_contents', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let acpId = response.data.result,
                                contentKey = '',
                                obj = {};

                            if (type == 'sentence') {
                                obj = {
                                    apc_id: acpId,
                                    apc_no: params.no,
                                    type: 'sentence',
                                    english: '',
                                    chinese: '',
                                    comment: '',
                                    cover: {
                                        name: '',
                                        path: '',
                                        file: ''
                                    },
                                    comment_time_point_start: null,
                                    comment_time_point_end: null,
                                    inputErr: false
                                }
                                contentKey = 'english'+ acpId;
                            }
                            else {
                                obj = {
                                    apc_id: acpId,
                                    apc_no: params.no,
                                    type: 'image',
                                    cover: {
                                        name: '',
                                        path: '',
                                        file: ''
                                    },
                                    comment: ''
                                }

                                contentKey = 'description'+ acpId;
                            }

                            this.paragraphList[index].contents.splice(cI+1, 0, obj);
                            this.oldParagraphList[index].contents.splice(cI+1, 0, obj); // 因為[新增段落]是直接更新資料庫，所以比對資料也要新增
                            this.oldParagraphList = this.$_.cloneDeep(this.oldParagraphList); // 必須要再複製一次，不然新舊資料會一樣，到updateArticleData會比對不出來
                            this.paragraphList[index].contents.forEach((el, key) => {
                                if (key > cI+1) {
                                    el.apc_no = parseInt(el.apc_no)+1;
                                    this.oldParagraphList[index].contents[key].apc_no = parseInt(this.oldParagraphList[index].contents[key].apc_no)+1;
                                }
                            })
                            // let focus new content's input
                            this.$nextTick(() => this.$refs[contentKey][0].focus());
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        addWordBlock(index, no) { // 新增[單字補充]區塊
            let params = {
                articleId: this.$route.params.id,
                no: parseInt(no)+1
            };

            this.$httpRequest.post('/admin_api/article/create_additional_materials', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let aamId = response.data.result.aam_id,
                                aamcId = response.data.result.aamc_id,
                                obj = {
                                    aam_id: aamId,
                                    aam_no: params.no,
                                    name: '',
                                    type: 0,
                                    comment_time_point_start: null,
                                    comment_time_point_end: null,
                                    inputErr: false,
                                    contents : [
                                        {
                                            aamc_id: aamcId,
                                            aamc_no: 1,
                                            word_sentence: '',
                                            speech_type: '0',
                                            chinese_explain: '',
                                            comment: '',
                                            example: [],
                                        }
                                    ]
                                };

                            this.wordBlockList.splice(index+1, 0, obj);
                            this.oldWordBlockList.splice(index+1, 0, obj); // 因為[新增段落]是直接更新資料庫，所以比對資料也要新增
                            this.wordBlockList.forEach((el, key) => { // 新增項目之後的項目no都要+1
                                if (key > index+1) {
                                    el.aam_no = parseInt(el.aam_no)+1;
                                    this.oldWordBlockList[key].aam_no = parseInt(this.oldWordBlockList[key].aam_no)+1;
                                }
                            });
                            this.oldWordBlockList = this.$_.cloneDeep(this.oldWordBlockList); // 必須要再複製一次，不然新舊資料會一樣，到updateArticleData會比對不出來
                            // let focus new wordblock's input
                            this.$nextTick(() => this.$refs['block'+aamId][0].focus());
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        addWord(aamId, index, wI, no) { // 新增[單字]
            let params = {
                articleId: this.$route.params.id,
                aamId: aamId,
                no: parseInt(no)+1
            };

            this.$httpRequest.post('/admin_api/article/create_material_contents', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let aamcId = response.data.result,
                                obj = {
                                    aamc_id: aamcId,
                                    aamc_no: params.no,
                                    word_sentence: '',
                                    speech_type: '0',
                                    chinese_explain: '',
                                    comment: '',
                                    example: []
                                };

                            this.wordBlockList[index].contents.splice(wI+1, 0, obj);
                            this.oldWordBlockList[index].contents.splice(wI+1, 0, obj); // 因為[新增段落]是直接更新資料庫，所以比對資料也要新增
                            this.oldWordBlockList = this.$_.cloneDeep(this.oldWordBlockList); // 必須要再複製一次，不然新舊資料會一樣，到updateArticleData會比對不出來
                            this.wordBlockList[index].contents.forEach((el, key) => {
                                if (key > wI+1) {
                                    el.aamc_no = parseInt(el.aamc_no)+1;
                                    this.oldWordBlockList[index].contents[key].aamc_no = parseInt(this.oldWordBlockList[index].contents[key].aamc_no)+1;
                                }
                            })
                            // let focus new word's input
                            this.$nextTick(() => this.$refs['word' + aamcId][0].focus());
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        addExample(aamcId, index, wI) { // 新增[例句中文翻譯]
            let params = {
                articleId: this.$route.params.id,
                aamcId: aamcId
            };

            this.$httpRequest.post('/admin_api/article/create_content_example', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        if (response.data.result) {
                            let aamceId = response.data.result,
                                obj = {
                                    aamce_id: aamceId,
                                    example_sentence: '',
                                    sentence_explain: '',
                                },
                                newIndex = this.wordBlockList[index].contents[wI].example.length; // 直接找出例句翻譯的陣列長度當作新例句翻譯的index

                            this.wordBlockList[index].contents[wI].example.splice(newIndex, 0, obj);
                            this.oldWordBlockList[index].contents[wI].example.splice(newIndex, 0, obj); // 因為[新增段落]是直接更新資料庫，所以比對資料也要新增
                            this.oldWordBlockList = this.$_.cloneDeep(this.oldWordBlockList); // 必須要再複製一次，不然新舊資料會一樣，到updateArticleData會比對不出來

                            // let focus new example's input
                            this.$nextTick(() => this.$refs['example' + aamceId][0].focus());
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        deleteParagraph(index) {
            if (this.paragraphList.length > 1) {
                let apId = this.paragraphList[index].ap_id;
                this.paragraphList.splice(index, 1);
                this.oldParagraphList.splice(index, 1); // 比對資料也要刪除
                if (this.$_.indexOf(this.deleteParagraphIds, apId) == -1) {
                    this.deleteParagraphIds.push(apId);
                }
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '段落不能全部移除', status: 'danger' });
            }
        },
        deleteContent(index, cI) {
            if (this.paragraphList[index].contents.length > 1) {
                let apcId = this.paragraphList[index].contents[cI].apc_id;
                this.paragraphList[index].contents.splice(cI, 1);
                this.oldParagraphList[index].contents.splice(cI, 1);
                if (this.$_.indexOf(this.deleteSentenceIds, apcId) == -1) {
                    this.deleteSentenceIds.push(apcId);
                }
            }
            else {
                this.deleteParagraph(index);
            }
        },
        deleteBlock(index) {
            let aamId = this.wordBlockList[index].aam_id;
            this.wordBlockList.splice(index, 1);
            this.oldWordBlockList.splice(index, 1); // 比對資料也要刪除
            if (this.$_.indexOf(this.deleteMaterialIds, aamId) == -1) {
                this.deleteMaterialIds.push(aamId);
            }
        },
        deleteWord(index, wI) {
            if (this.wordBlockList[index].contents.length > 1) {
                let aamcId = this.wordBlockList[index].contents[wI].aamc_id;
                this.wordBlockList[index].contents.splice(wI, 1);
                this.oldWordBlockList[index].contents.splice(wI, 1);
                if (this.$_.indexOf(this.deleteContentIds, aamcId) == -1) {
                    this.deleteContentIds.push(aamcId);
                }
            }
            else {
                this.deleteBlock(index);
            }
        },
        deleteExample(aamceId, index, wI, eI) {
            this.wordBlockList[index].contents[wI].example.splice(eI, 1);
            this.oldWordBlockList[index].contents[wI].example.splice(eI, 1);
            if (this.$_.indexOf(this.deleteExampleIds, aamceId) == -1) {
                this.deleteExampleIds.push(aamceId);
            }
        },
        showSortParagraphDialogue() {
            this.sortParagraphList = [];
            this.paragraphList.forEach((el) => {
                let idList = {
                    ap_id : el.ap_id,
                    contents : []
                }
                el.contents.forEach((sub) => {
                    idList.contents.push({ apc_id: sub.apc_id});
                });
                this.sortParagraphList.push(idList);
            });
            $('#showSortParagraphDialogue').modal({ backdrop: 'static', keyboard: false });
        },
        setParagraphTwoIndex(evt) {
            this.paragraphOldIndex = null;
            this.paragraphNewIndex = null;
            // 把draggable拖拉功能的@end取得的新舊index存起來，以供sortParagraph使用
            this.paragraphOldIndex = evt.oldIndex;
            this.paragraphNewIndex = evt.newIndex;
        },
        sortParagraph() {
            // 找出舊的項目
            let oldContentItem = this.oldParagraphList[this.paragraphOldIndex],
                newContentItem = this.paragraphList[this.paragraphOldIndex];
            // 刪除舊的項目
            this.oldParagraphList.splice(this.paragraphOldIndex, 1);
            this.paragraphList.splice(this.paragraphOldIndex, 1);
            // 插入到新的位子
            this.oldParagraphList.splice(this.paragraphNewIndex, 0, oldContentItem);
            this.paragraphList.splice(this.paragraphNewIndex, 0, newContentItem);
            $('#showSortParagraphDialogue').modal('hide');
            this.isSort = true;
        },
        setParagraphIndex(index) {
            this.paragraphIndex = null;
            // 因為draggable拖拉功能的@end只能傳event，不能帶參數(paragraph的index)進來
            // @change事件會比@end事件早執行，所以先透過setParagraphIndex來設定paragraph的index
            this.paragraphIndex = index;
        },
        sortParagraphContents(evt) {
            let contentItem = this.oldParagraphList[this.paragraphIndex].contents[evt.oldIndex]; // 找出舊的項目
            this.oldParagraphList[this.paragraphIndex].contents.splice(evt.oldIndex, 1); // 刪除舊的項目
            this.oldParagraphList[this.paragraphIndex].contents.splice(evt.newIndex, 0, contentItem); // 插入到新的位子
            this.isSort = true;
        },
        showSortWordBlockDialogue() {
            this.sortWordBlockList = [];
            this.wordBlockList.forEach((el) => {
                let idList = {
                    aam_id : el.aam_id,
                    contents : []
                }
                el.contents.forEach((sub) => {
                    idList.contents.push({ aamc_id: sub.aamc_id});
                });
                this.sortWordBlockList.push(idList);
            });
            $('#showSortWordBlockDialogue').modal({ backdrop: 'static', keyboard: false });
        },
        setWordBlockTwoIndex(evt) {
            this.wordBlockOldIndex = null;
            this.wordBlockNewIndex = null;
            // 把draggable拖拉功能的@end取得的新舊index存起來，以供sortWordBlock使用
            this.wordBlockOldIndex = evt.oldIndex;
            this.wordBlockNewIndex = evt.newIndex;
        },
        sortWordBlock() {
            let oldContentItem = this.oldWordBlockList[this.wordBlockOldIndex], // 找出舊的項目
                newContentItem = this.wordBlockList[this.wordBlockOldIndex];

            this.oldWordBlockList.splice(this.wordBlockOldIndex, 1); // 刪除舊的項目
            this.wordBlockList.splice(this.wordBlockOldIndex, 1);
            this.oldWordBlockList.splice(this.wordBlockNewIndex, 0, oldContentItem); // 插入到新的位子
            this.wordBlockList.splice(this.wordBlockNewIndex, 0, newContentItem);
            $('#showSortWordBlockDialogue').modal('hide');
            this.isSort = true;
        },
        setWordBlockIndex(index) {
            this.wordBlockIndex = null;
            // 因為draggable拖拉功能的@end只能傳event，不能帶參數(wordBlock的index)進來
            // @change事件會比@end事件早執行，所以先透過setWordBlockIndex來設定wordBlock的index
            this.wordBlockIndex = index;
        },
        sortWordBlockContents(evt) {
            let contentItem = this.oldWordBlockList[this.wordBlockIndex].contents[evt.oldIndex]; // 找出舊的項目
            this.oldWordBlockList[this.wordBlockIndex].contents.splice(evt.oldIndex, 1); // 刪除舊的項目
            this.oldWordBlockList[this.wordBlockIndex].contents.splice(evt.newIndex, 0, contentItem); // 插入到新的位子
            this.isSort = true;
        },
        uploadContentCoverFile(index, cI, apcId, apccfId) { // [詞句]的解說圖片和[圖片]的圖片共用此function來上傳圖片
            let v = this;

            this.uploadErrorMsg.cover = null;

            let tempUploadFile = this.$refs['uploadContentCover' + apcId][0].files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.cover = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.cover && tempUploadFile.type.match(/^image\//)) {

                this.paragraphList[index].contents[cI].cover = this.$_.mapValues(v.paragraphList[index].contents[cI].cover, () => null);

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = function() {
                    let dataURL = reader.result;
                    v.paragraphList[index].contents[cI].cover.path = dataURL;
                }

                this.paragraphList[index].contents[cI].cover.name = tempUploadFile.name;
                this.paragraphList[index].contents[cI].cover.file = tempUploadFile;

                if (tempUploadFile && apccfId) {
                    // 如果有上傳的檔案和id，就把之前的id丟進去deleteCommentFileIds陣列裡
                    if (this.$_.indexOf(this.deleteCommentFileIds, apccfId) == -1) {
                        this.deleteCommentFileIds.push(apccfId);
                    }
                }
            }
            else {
                this.$store.dispatch('common/setAlert', { msg: '檔案超過5MB或格式不符，請重新上傳', status: 'danger' });
            }

            this.$refs['uploadContentCover' + apcId][0].value = null;
        },
        deleteCommentFile(apccfId) {
            if (this.$_.indexOf(this.deleteCommentFileIds, apccfId) == -1) {
                this.deleteCommentFileIds.push(apccfId);
            }
        },
        // 有聲文本
        insertSentence(index) {
            let template = {
                text: "",
                voiceInterval: 250
            }
            this.voiceOfArticleList.splice(index+1, 0, template);
            this.oldVoiceOfArticleList.splice(index+1, 0, template);
            this.voiceOfArticleIsChange = true;
            // 需要讓textarea去適應新增後不同的文字高度
            this.$nextTick(() => this.$node.resizeTextarea());
        },
        deleteSentence(index) {
            this.voiceOfArticleList.splice(index, 1);
            this.oldVoiceOfArticleList.splice(index, 1);
            this.voiceOfArticleIsChange = true;
            // 需要讓textarea去適應刪除後不同的文字高度
            this.$nextTick(() => this.$node.resizeTextarea());
        },
        playAudio(index) {
            this.audio.paused;
            this.audio.src = this.voiceOfArticleList[index].audioPath;
            this.audio.play();
        }
    }
}
