import Chart from 'chart.js/auto'
import { getRecognizedDisplayedText, getRecognizedComment, getRevisedSentences, getGradingResults, getRevisedParagraphs } from '@/assets/js/utils/article_practice'
import { quesTypes } from '@/assets/js/consts/article_practice'
import { writingTypes, feedbackModes } from '@/assets/js/consts/writing'

// common scss variables
import colors from '@/assets/scss/modules/_colors.scss'
import variables from '@/assets/scss/modules/_variables.scss'

// components
const ReviewQuestionsDialogue = resolve => require(["@/components/article/practice/_review_questions_dialogue.vue"], resolve)
const WritingReviewDescription = resolve => require(["@/components/article/practicing/write/_writing_review_description.vue"], resolve)

export default {
    beforeCreate: function() {
        this.isShowFooter = false;
    },
    components: {
        ReviewQuestionsDialogue,
        WritingReviewDescription,
    },
    data: function() {
        return {
            testedIds: [],
            testedInfos: {},
            lastTestedId: '',  // 最後一次練習的 testedId
            studentName: '',
            chartYAxisMax: 0,

            practiceBasicInfo: {},  // 練習基本資訊

            // v1
            allQuestionBasicInfoList: [],  // 練習的題目基本資訊(含停用題目)
            enabledQuestionList: [],  // 練習的題目詳細資訊(不含停用題目)
            handInResults: {},  // 每題作答的結果
            // v2
            allQIds: [],
            qIds: [],
            qInfos: {},
            qActiveIndex: 0,

            selectedQuestionId: '0',  // 被選擇的題目 id
            isGettingQuestionInfo: false,  // 是否正在取得題目資訊 (再次作答)

            isSetDataReady: {
                getQuestions: false,  // 取得某次練習作答紀錄
            },
        }
    },
    watch: {
        '$route.query.tested'() {
            this.getQuestionList();
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        isShowFooter: {
            get() {
                return this.$store.state.common.isShowFooter;
            },
            set(value) {
                this.$store.commit('common/setIsShowFooter', value);
            }
        },
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        articlePracticeInfo() {
            return this.$store.state.article.articlePracticeInfo;
        },
        isPracticing: {
            get() {
                return this.$store.state.article.isPracticing;
            },
            set(value) {
                this.$store.commit('article/setIsPracticing', value);
            }
        },
        practicingCategory: {
            get() {
                return this.$store.state.article.practicingCategory;
            },
            set(value) {
                this.$store.commit('article/setPracticingCategory', value);
            },
        },
        selectedTestedId() {
            return this.$route.query.tested || this.lastTestedId;
        },
    },
    created: function() {
        this.initialize();
    },
    beforeDestroy: function() {
        this.isShowFooter = true;
    },
    methods: {
        async initialize() {
            await this.getTestedList();
            this.getQuestionList();
        },

        getTestedList() {
            return new Promise((resolve, reject) => {
                const params = {
                    practiceCategoryId: this.$route.params.categoryId,
                };

                // 有指定學生
                if (this.$route.query.student) {
                    params.studentId = this.$route.query.student;
                }

                this.$httpRequest.get('/api/practice/get_practice_tested_list', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                // 沒有任何練習紀錄, 則導回分類列表頁面
                                if (!result.list.length) {
                                    this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practice`);
                                    return;
                                }

                                for (const el of result.list) {
                                    const testedId = el.id;
                                    this.testedIds.push(testedId);
                                    this.lastTestedId = testedId;

                                    if (['1', '3'].includes(el.score_type)) {
                                        this.chartYAxisMax = 5;
                                    }
                                    // 非口說題, 取得最大的總題數數值
                                    else {
                                        if (+el.total_questions > this.chartYAxisMax) {
                                            this.chartYAxisMax = +el.total_questions;
                                        }
                                    }

                                    const testedInfo = {
                                        no: el.no,
                                        time: this.$util.unixTimestampToDatetime(el.enterdate, this.$util.getBrowserCurrentTimeZone(), 'datetime', true),
                                        datasetsData: ['1', '3'].includes(el.score_type) ? +el.score : +el.correct_questions,
                                    };
                                    this.$set(this.testedInfos, testedId, testedInfo);
                                }

                                // 有指定某次練習紀錄
                                if (this.$route.query.tested) {
                                    // 指定的 testedId 不包含在最後 10 筆紀錄裡
                                    if (!this.testedIds.includes(this.$route.query.tested)) {
                                        // 刪除 query string 'tested', 以查看列表中最後一筆練習紀錄
                                        const query = Object.assign({}, this.$route.query);
                                        this.$delete(query, 'tested');
                                        this.$router.replace({ query });
                                    }
                                }

                                // 有指定學生
                                if (this.$route.query.student) {
                                    this.studentName = result.student_name;
                                }

                                resolve();
                            }
                        }
                        // 若有錯誤訊息, 則導回分類列表頁面
                        if (response.data.state == 'ERROR') {
                            this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practice`);
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async getQuestionList() {
            await this.getQuestionsByTestedId();
            this.$nextTick(() => this.setChart());
        },
        getQuestionsByTestedId() {
            return new Promise((resolve, reject) => {
                this.isSetDataReady.getQuestions = false;

                // v1
                this.allQuestionBasicInfoList = [];
                this.enabledQuestionList = [];
                // v2
                this.allQIds = [];
                this.qIds = [];
                this.qInfos = {};

                const params = {
                    practiceTestedId: this.selectedTestedId,
                };

                // 有指定學生
                if (this.$route.query.student) {
                    params.studentId = this.$route.query.student;
                }

                this.$httpRequest.get('/api/practice/get_practice_history', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                // 練習基本資訊
                                this.practiceBasicInfo = {
                                    categoryId: result.pc_id,
                                    categoryName: result.name,
                                    categoryScoreType: result.score_type,
                                    score: +result.score,
                                    isShowScore: result.is_show_score,
                                    correctCount: +result.correct_questions,
                                    totalCount: +result.total_questions,
                                    isReachedTimes: !result.is_re_practice,  // 是否已到達練習次數上限
                                };

                                // 設置選項
                                const setOptions = (options) => {
                                    let tempOptions = [];
                                    for (const option of options) {
                                        // 選項圖片
                                        let tempOptionImage = {};
                                        if (option.title.file_info.images[0]) {
                                            tempOptionImage.url = option.title.file_info.images[0].file_url;
                                        }

                                        tempOptions.push({
                                            id: option.title.qo_id,
                                            text: option.title.text,
                                            image: tempOptionImage,
                                        });
                                    }

                                    return tempOptions;
                                };

                                // 設置解析
                                const setComment = (comment) => {
                                    // 解析檔案
                                    let tempCommentFile = {};
                                    if (!this.$_.isEmpty(comment) && comment.file_url && comment.file_type) {
                                        tempCommentFile = {
                                            type: comment.file_type.split('/')[0],
                                            url: comment.file_url,
                                        };
                                    }
                                    // 解析內容
                                    let tempComment = null;
                                    if (!this.$_.isNull(comment)) {
                                        tempComment = {};
                                        tempComment = {
                                            file: tempCommentFile,
                                            text: comment.text,
                                        };
                                    }

                                    return tempComment;
                                };

                                // 設置作答結果
                                const setHandInAnswer = (questionId, options) => {
                                    let tempHandin = result.student_answers[questionId];
                                    let optionContentType = this.articleUtil.setChoiceOptionContentTypeInfo(options);

                                    // 單選/多選: 使用者選擇的選項文字
                                    let tempHandinTexts = [];
                                    for (const optionId of tempHandin.student_answer.answerIds) {
                                        const type = optionContentType.option[optionId];
                                        // 1: ['text'] ; 3: ['text', 'image']
                                        if (type === 1 || type === 3) {
                                            const foundOption = this.$_.find(options, (el) => {
                                                return el.id === optionId;
                                            });
                                            tempHandinTexts.push(foundOption.text);
                                        }
                                    }

                                    // 單選/多選: 正確答案的選項文字
                                    let tempStandardTexts = [];
                                    for (const optionId in tempHandin.right_answer.right_option_status) {
                                        // true 表示為正確答案
                                        if (tempHandin.right_answer.right_option_status[optionId]) {
                                            const type = optionContentType.option[optionId];
                                            // 1: ['text'] ; 3: ['text', 'image']
                                            if (type === 1 || type === 3) {
                                                const foundOption = this.$_.find(options, (el) => {
                                                    return el.id === optionId;
                                                });
                                                tempStandardTexts.push(foundOption.text);
                                            }
                                        }
                                    }

                                    // 口說題
                                    let recognizedResult = {};
                                    let recognizedComment = '';
                                    let handInAudio = {};
                                    if (['1', '3'].includes(this.practiceBasicInfo.categoryScoreType)) {
                                        // 辨識結果
                                        if (tempHandin.right_answer.recognize_result) {
                                            recognizedResult.text = getRecognizedDisplayedText(tempHandin.right_answer.recognize_result.text);  // 辨識結果各狀態文字
                                            recognizedResult.correctRate = `${tempHandin.right_answer.recognize_result.lightRate}%`;  // 正確率
                                            recognizedResult.fluency = tempHandin.right_answer.recognize_result.fluency;  // 流暢度
                                        }

                                        // 辨識後分數的評語
                                        recognizedComment = getRecognizedComment(tempHandin.score);

                                        // 錄音音檔
                                        handInAudio.url = tempHandin.student_answer.audio_url;
                                        handInAudio.isPlayingSound = false;  // 是否正在播放音檔
                                    }

                                    const handInResult = {
                                        isCorrect: tempHandin.is_correct,  // 是否答題正確
                                        score: +tempHandin.score,  // 分數
                                        handInContent: tempHandin.student_answer.answerContent,  // 填充題: 使用者填入的答案
                                        handInWords: tempHandin.student_answer.answerWords ? tempHandin.student_answer.answerWords : [],  // 重組題: 使用者的答案
                                        handInAudio: handInAudio,  // 口說題: 使用者的錄音音檔
                                        answerInfo: {
                                            optionStatusStyle: this.articleUtil.setHandInOptionStatusStyle(tempHandin.right_answer.right_option_status, tempHandin.student_answer.answerIds),  // key name 為 選項 id
                                            answerContents: tempHandin.right_answer.right_answer_content,  // 填充/重組題: 正確答案
                                        },
                                        optionText: {  // 選項文字
                                            handIn: tempHandinTexts,
                                            standard: tempStandardTexts,
                                        },
                                        recognizedComment: recognizedComment,  // 口說題: 辨識後分數的評語
                                        recognizedResult: recognizedResult,  // 口說題: 辨識結果
                                    };
                                    this.$set(this.handInResults, questionId, handInResult);
                                }

                                // 未來將由各題型自行處理資料結構內容 (by Eva 2024.05.09)
                                // 練習題目
                                for (const el of result.questions) {
                                    // 單題題
                                    if (this.practiceBasicInfo.categoryScoreType === '0') {
                                        // 題目/題幹音檔
                                        let tempTitleAudio = {};
                                        if (el.title.file_info.audios[0]) {
                                            tempTitleAudio.url = el.title.file_info.audios[0].file_url;
                                            tempTitleAudio.isPlayingSound = false;  // 是否正在播放音檔
                                        }
                                        // 題目/題幹圖片
                                        let tempTitleImage = {};
                                        if (el.title.file_info.images[0]) {
                                            tempTitleImage.url = el.title.file_info.images[0].file_url;
                                        }

                                        // 題目基本資訊
                                        this.allQuestionBasicInfoList.push({
                                            id: el.id,
                                            answerType: el.answer_type,
                                            isHasComment: el.comment !== null,  // 是否有解析
                                            isDisabled: el.is_disable,  // 是否已停用
                                        });

                                        // 題目詳細資訊
                                        if (!el.is_disable) {
                                            this.enabledQuestionList.push({
                                                id: el.id,
                                                answerType: el.answer_type,
                                                score: el.score,
                                                title: {
                                                    audio: tempTitleAudio,
                                                    text: el.title.text,
                                                    image: tempTitleImage,
                                                },
                                                options: setOptions(el.options),  // 選項
                                                comment: setComment(el.comment),  // 解析
                                            });

                                            // 作答結果
                                            setHandInAnswer(el.id, setOptions(el.options));
                                        }
                                    }
                                    // 朗讀題
                                    if (this.practiceBasicInfo.categoryScoreType === '1') {
                                        // 題目基本資訊
                                        this.allQuestionBasicInfoList.push({
                                            id: el.id,
                                            answerType: el.answer_type,
                                            isDisabled: el.is_disable,  // 是否已停用
                                        });

                                        // 題目詳細資訊
                                        if (!el.is_disable) {
                                            this.enabledQuestionList.push({
                                                id: el.id,
                                                answerType: el.answer_type,
                                                title: {
                                                    audio: '',
                                                    text: '',
                                                    image: '',
                                                },
                                                text: el.options[0].title.text,
                                                image: el.title.file_info.images[0] ? el.title.file_info.images[0].file_url : '',
                                                translation: el.title.text,
                                                demoAudio: {
                                                    url: el.title.file_info.audios[0].file_url,
                                                    isPlayingSound: false,
                                                },
                                                showText: el.is_show_speak_text,
                                            });

                                            // 作答結果
                                            setHandInAnswer(el.id, setOptions(el.options));
                                        }
                                    }
                                    // 題組題
                                    if (this.practiceBasicInfo.categoryScoreType === '2') {
                                        // 題目/題幹音檔
                                        let tempTitleAudio = {};
                                        if (el.title.file_info.audios[0]) {
                                            tempTitleAudio.url = el.title.file_info.audios[0].file_url;
                                            tempTitleAudio.isPlayingSound = false;  // 是否正在播放音檔
                                        }
                                        // 題目/題幹圖片
                                        let tempTitleImage = {};
                                        if (el.title.file_info.images[0]) {
                                            tempTitleImage.url = el.title.file_info.images[0].file_url;
                                        }

                                        for (const sq of el.sub_questions) {
                                            // 子題圖片
                                            let tempChildQuesImage = {};
                                            if (sq.title.file_info.images[0]) {
                                                tempChildQuesImage.url = sq.title.file_info.images[0].file_url;
                                            }

                                            // 題目基本資訊
                                            this.allQuestionBasicInfoList.push({
                                                id: sq.id,
                                                answerType: sq.answer_type,
                                                isHasComment: sq.comment !== null,  // 是否有解析
                                                isDisabled: el.is_disable,  // 是否已停用
                                            });

                                            // 題目詳細資訊
                                            if (!el.is_disable) {
                                                this.enabledQuestionList.push({
                                                    id: sq.id,
                                                    groupId: sq.group_question_id,
                                                    answerType: sq.answer_type,
                                                    score: sq.score,
                                                    title: {  // 題幹
                                                        audio: tempTitleAudio,
                                                        text: el.title.text,
                                                        image: tempTitleImage,
                                                    },
                                                    childQuesTitle: {  // 子題
                                                        text: sq.title.text,
                                                        image: tempChildQuesImage,
                                                    },
                                                    options: setOptions(sq.options),  // 選項
                                                    comment: setComment(sq.comment),  // 解析
                                                });

                                                // 作答結果
                                                setHandInAnswer(sq.id, setOptions(sq.options));
                                            }
                                        }
                                    }
                                    // 對話題
                                    if (this.practiceBasicInfo.categoryScoreType === '3') {
                                        // 題目基本資訊
                                        let bq = {
                                            id: el.id,
                                            answerType: el.answer_type,
                                            isDisabled: el.is_disable,  // 是否已停用
                                            firstMsgId: '',  // 對話第一句 qId
                                        };

                                        // 題目詳細資訊
                                        if (!el.is_disable) {
                                            let q = {
                                                id: el.id,
                                                title: {
                                                    audio: '',
                                                    text: '',
                                                    image: '',
                                                },
                                                msgs: [],
                                            };

                                            for (const sq of el.sub_questions) {
                                                if (!bq.firstMsgId) {
                                                    bq.firstMsgId = sq.id;
                                                }

                                                const msg = {
                                                    id: sq.id,
                                                    role: sq.voice_group === '0' ? 'sender' : 'receiver',
                                                    defaultAvatar: sq.options[0].title.file_info.images[0].file_url,
                                                    text: sq.options[0].title.text,
                                                    translation: sq.title.text,
                                                    demoAudio: {
                                                        url: sq.title.file_info.audios[0].file_url,
                                                        isPlayingSound: false,
                                                    },
                                                };
                                                q.msgs.push(msg);

                                                // 作答結果
                                                setHandInAnswer(sq.id, setOptions(sq.options));
                                            }

                                            this.enabledQuestionList.push(q);
                                        }

                                        this.allQuestionBasicInfoList.push(bq);
                                    }
                                    // 翻譯題
                                    if (this.practiceBasicInfo.categoryScoreType === '4') {
                                        const qId = el.id;
                                        this.allQIds.push(qId);
                                        if (!el.is_disable) {
                                            this.qIds.push(qId);
                                            const handin = result.student_answers[qId];
                                            const qInfo = {
                                                id: qId,
                                                answerType: el.answer_type,
                                                quesType: quesTypes[el.answer_type],
                                                text: el.title.text,
                                                description: el.description,
                                                comment: el.comment ? el.comment.text : '',
                                                practiceAnswer: handin.student_answer.answerContent,
                                                answer: handin.right_answer.right_answer_content[0],
                                                answerMaxLength: 60,
                                                feedback: handin.right_answer.ai_result,
                                            };
                                            this.$set(this.qInfos, qId, qInfo);
                                        }
                                    }
                                    // 寫作題
                                    if (this.practiceBasicInfo.categoryScoreType === '5') {
                                        const qId = el.id;
                                        this.allQIds.push(qId);
                                        if (!el.is_disable) {
                                            this.qIds.push(qId);

                                            let qInfo = {
                                                id: qId,
                                                answerType: el.answer_type,
                                                quesType: quesTypes[el.answer_type],
                                                text: el.title.text,
                                                description: el.description,
                                                images: [],
                                                writingMode: '1',
                                                writingParagraphs: [],
                                                writingTextParagraphs: [],
                                                briefWritingTexts: [],
                                                writingTextLength: 0,
                                                writingTextMaxLength: 600,
                                                reviewTabs: {
                                                    default: {
                                                        name: '題目+我的寫作',
                                                        contents: [],
                                                    },
                                                },
                                                formattedPracticeTime: this.$util.formatSecToTimeText('221', 'mm:ss', false),  // !!! el.time
                                            };
                                            // question images
                                            for (const image of el.title.file_info.images) {
                                                qInfo.images.push(image.file_url);
                                            }
                                            // paragraphs & structures
                                            const handin = result.student_answers[qId];
                                            const ac = handin.student_answer.answerCompositions;
                                            for (const p of ac) {
                                                let paragraph = {
                                                    type: writingTypes[p.style],
                                                    structures: p.structs,
                                                };
                                                let texts = [];
                                                for (const s of p.structs) {
                                                    if (s.name && (qInfo.writingMode === '1')) {
                                                        qInfo.writingMode = '2';
                                                    }
                                                    const text = s.text;
                                                    if (qInfo.briefWritingTexts.length < 20) {
                                                        qInfo.briefWritingTexts = [...qInfo.briefWritingTexts, ...text.split(/\s+/)];
                                                    }
                                                    texts.push(text);
                                                }
                                                qInfo.writingParagraphs.push(paragraph);
                                                qInfo.writingTextParagraphs.push(texts.join(' '));
                                            }
                                            const writingText = qInfo.writingTextParagraphs.join(' ');
                                            qInfo.writingTextLength = writingText.trim() !== '' ? writingText.trim().split(/\s+/).length : 0;
                                            // feedback modes
                                            const modes = el.composition_correction_mechanism.modes;
                                            const feedbackResults = handin.right_answer.composition_ai_result;
                                            for (const mode of Object.keys(feedbackModes)) {
                                                if (!modes[mode]) continue;

                                                let feedbackInfo = {
                                                    name: feedbackModes[mode].tabName,
                                                    contents: [],
                                                };
                                                switch (mode) {
                                                    // 逐句批改
                                                    case 'sentence': {
                                                        if (!feedbackResults[mode]) break;

                                                        if (!feedbackResults[mode].is_processing) {
                                                            const originalSentences = feedbackResults[mode].result.sentence_revision;
                                                            feedbackInfo.contents = getRevisedSentences(originalSentences);
                                                        }
                                                        break;
                                                    }
                                                    // 總評批改
                                                    case 'summary': {
                                                        if (!feedbackResults[mode]) break;

                                                        if (!feedbackResults[mode].is_processing) {
                                                            const originalResults = feedbackResults[mode].result.infos;
                                                            feedbackInfo.contents = getGradingResults(originalResults);
                                                        }
                                                        break;
                                                    }
                                                    // 原文優化
                                                    case 'optimize': {
                                                        if (!feedbackResults[mode]) break;

                                                        if (!feedbackResults[mode].is_processing) {
                                                            const originalParagraphs = feedbackResults[mode].result.revision_results;
                                                            feedbackInfo.contents = getRevisedParagraphs(originalParagraphs);
                                                        }
                                                        break;
                                                    }
                                                }
                                                qInfo.reviewTabs[mode] = {...feedbackInfo};
                                            }

                                            this.$set(this.qInfos, qId, qInfo);
                                        }
                                    }
                                }

                                this.isSetDataReady.getQuestions = true;

                                resolve();
                            }
                        }
                        // 若有錯誤訊息, 則導回分類列表頁面
                        if (response.data.state == 'ERROR') {
                            reject('Get Questions Error');
                            this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practice`);
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        setChart() {
            const elem = document.getElementById('mainChart');

            if (!elem) return;

            const s1 = {
                borderColor: colors.orangedark,
                backgroundColor: colors.orangedark,
                clip: { left: false, top: false, right: false, bottom: false },
                data: [],
            };
            for (const id of this.testedIds) {
                s1.data.push(this.testedInfos[id].datasetsData);
            }
            const data = {
                labels: this.testedIds.map((id) => this.testedInfos[id].time.split(' ')[0]),
                datasets: [s1],
            };

            const options = {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 30,
                    },
                },
                elements: {
                    point: {
                        radius: 2,
                        borderWidth: 0,
                        hoverRadius: 3,
                    },
                    line: {
                        tension: 0.01,
                    },
                },
                scales: {
                    x: {
                        offset: true,
                        ticks: {
                            color: colors.blacknormal,
                            font: {
                                family: variables.fontfamilymain,
                                size: 14,
                            },
                            minRotation: 30,
                            labelOffset: 25,
                        },
                        grid: {
                            drawBorder: false,
                            drawOnChartArea: false,
                            color: colors.graylight207,
                            tickColor: '',
                        },
                    },
                    y: {
                        min: 0,
                        max: this.chartYAxisMax,
                        ticks: {
                            stepSize: 1,
                            autoSkip: false,
                            color: colors.blacknormal,
                            font: {
                                family: variables.fontfamilymain,
                                size: 14,
                            },
                        },
                        grid: {
                            drawBorder: false,
                            color: (context) => {
                                if (context.tick.value === this.chartYAxisMax) {
                                    return colors.bluedark;
                                } else if (context.tick.value === 0) {
                                    return colors.blacknormal;
                                }

                                return colors.graylight207;
                            },
                            tickLength: 20,
                            tickColor: '',
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
            };

            const plugin = {
                afterDraw: (chart) => {
                    let ctx = chart.ctx;
                    ctx.save();
                    const xAxis = chart.scales.x;
                    const yAxis = chart.scales.y;
                    const data = chart.data.datasets[0].data;
                    for (let i = 0; i < xAxis.ticks.length; i++) {
                        const x = xAxis.getPixelForTick(i);
                        const yTop = yAxis.getPixelForValue(data[i]);
                        ctx.strokeStyle = colors.graylight207;
                        ctx.beginPath();
                        ctx.setLineDash([4, 4]);
                        ctx.moveTo(x, yAxis.bottom);
                        ctx.lineTo(x, yTop + 4);  // 4 for point's diameter(radius*2)
                        ctx.stroke();
                        ctx.font = `500 12px ${variables.fontfamilymain}`;
                        ctx.fillStyle = colors.orangedark;
                        ctx.textAlign = 'center';
                        ctx.fillText(data[i], x, yTop - 15);  // 15 for offset
                    }
                    ctx.restore();
                },
            };

            const config = {
                type: 'line',
                data: data,
                options: options,
                plugins: [plugin],
            };
            new Chart(elem, config);
        },
        showReviewQuestionsDialogue(qId) {
            this.selectedQuestionId = qId;
            this.qActiveIndex = this.qIds.indexOf(this.selectedQuestionId);
            $('#reviewQuestionsDialogue').modal('show');
        },
        changeQuestionActiveIndex(action) {
            switch (action) {
                case 'prev': {
                    this.qActiveIndex--;
                    break;
                }
                case 'next': {
                    this.qActiveIndex++;
                    break;
                }
            }
        },

        async startPracticing() {
            if (this.isGettingQuestionInfo || this.practiceBasicInfo.isReachedTimes) {
                return;
            }

            // v2: 調整流程, 傳遞分類資訊到練習中頁面時再取得題目與分類資訊
            this.practicingCategory.id = this.practiceBasicInfo.categoryId;
            this.practicingCategory.scoreType = this.practiceBasicInfo.categoryScoreType;
            // 目前 v2 版適用類型: 朗讀、對話、翻譯、寫作
            if (['1', '3', '4', '5'].includes(this.practiceBasicInfo.categoryScoreType)) {
                this.isPracticing = true;
                this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practicing/v2`);
                return;
            }

            // v1: 點擊"開始練習"時取得題目完成後再前往練習中頁面
            // 目前 v1 版適用類型: 單題、題組
            const payload = {
                articleId: this.$route.params.id,
                categoryId: this.practiceBasicInfo.categoryId,
                categoryScoreType: this.practiceBasicInfo.categoryScoreType,
            };

            try {
                this.isGettingQuestionInfo = true;
                // 若無測驗分類資訊, 需先取得分類資訊後再取得題目內容
                if (!this.articlePracticeInfo) {
                    await this.$store.dispatch('article/getArticlePracticeInfo', payload);
                }
                await this.$store.dispatch('article/getPracticeQuestionInfo', payload);
                this.isGettingQuestionInfo = false;
                this.isPracticing = true;
                this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practicing`);
            } catch (error) {
                console.error('Catched Error:', error);
                this.$router.push(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practice`).catch(() => {});
            }
        },
    },
}
