/**
 * [朗讀/對話] 依據辨識結果取得顯示在畫面上的 html 內容
 * @param {Array} diffResults 辨識結果陣列
 * @returns {String} html 內容
 */
export const getRecognizedDisplayedText = (diffResults) => {
    // # Example 'diffResults' Format
    // [["0", "Knowledge "], ["1", "is power. "], ["-1", "apple"]]

    let displayedText = '';
    let texts = [];
    let isAllTextCorrect = true;

    // # Label  1 錯字或少字 ; -1 多字 ; 0 正常
    // 先掃過所有結果, 以得出是否為錯誤顯示
    // 如果 label 有 1 或 -1, 則判定為錯誤顯示, 正常: 黑字 ; 錯字: 紅字 ; 多字: 紅字+刪除線
    // 如果 label 全為 0, 則為正確顯示, 全部顯示為綠字
    diffResults.some(el => {
        if (el[0] === '1' || el[0] === '-1') {
            isAllTextCorrect = false;
        }
    });

    // 辨識結果與文本比對皆相同時(無錯字, 無多字)
    if (isAllTextCorrect) {
        diffResults.forEach((el, i) => {
            if (!el || !el[1]) {
                return;
            }

            const tempText = el[1].replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n|\r\n/g, '<br>');

            texts[i] = `<span style="color: #43C65E;">${tempText}</span>`;
        });
    } else {
        diffResults.forEach((el, i) => {
            if (!el || !el[1]) {
                return;
            }

            const tempText = el[1].replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n|\r\n/g, '<br>');
            let tempStyle = "";

            switch (el[0]) {
                case '1': {  // 錯字或少字
                    tempStyle = "color: #E05252;";
                    break;
                }
                case '-1': {  // 多字
                    tempStyle = "color: #E05252; text-decoration: line-through;";
                    break;
                }
            }

            texts[i] = `<span style="${tempStyle}">${tempText}</span>`;
        });
    }

    displayedText = texts.join('');

    return displayedText;
};

/**
 * [朗讀/對話] 依據辨識分數取得評語內容
 * @param {String} score 辨識分數
 * @returns {String} 評語內容
 */
export const getRecognizedComment = (score) => {
    const samples = {
        '0': [
            '再接再厲',
        ],
        '1': [
            '再多練習幾次吧',
            '聽完範例音檔再練習看看吧',
        ],
        '2': [
            '聽完範例音檔再練習看看吧',
        ],
        '3': [
            '唸得不錯唷',
            '加油 差一點就完美了',
        ],
        '4': [
            '唸得不錯唷',
            '加油 差一點就完美了',
        ],
        '5': [
            '太厲害了',
            '唸得太棒了',
            '發音很標準',
            '發音快媲美母語人士了',
        ],
    };

    let comment = '';
    if (samples[score]) {
        const comments = samples[score];
        comment = comments[Math.floor(Math.random() * comments.length)];
    }

    return comment;
};

/**
 * [寫作] 取得透過"逐句批改模組"批改後的內容
 * @param {Array} originalSentences 原始批改後的句子內容
 * @returns {Array} 資料處理後的句子內容
 */
export const getRevisedSentences = (originalSentences) => {
    let sentences = [];
    for (const s of originalSentences) {
        const results = s.revision_results;
        // handle diff results
        let revisedSentence = '', htmlTexts = [];
        if (!results.diff_results) {
            revisedSentence = results.the_revised_sentence;
        } else {
            for (const el of results.diff_results) {
                const text = el[1].replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n|\r\n/g, '<br>');
                switch (el[0]) {
                    case '0': {
                        htmlTexts.push(`<span>${text}</span>`);
                        break;
                    }
                    case '-1': {  // 錯字或少字
                        htmlTexts.push(`<span style="font-weight: 700">${text}</span>`);
                        break;
                    }
                }
            }
            revisedSentence = htmlTexts.join('');
        }
        const sentence = {
            original: results.original_sentence,
            revised: revisedSentence,
            explanation: results.explanation_of_revision,
        };
        sentences.push(sentence);
    }

    return sentences;
};

/**
 * [寫作] 取得透過"總評模組"批改後的內容
 * @param {Array} originalResults 原始批改後的總評內容
 * @returns {Array} 資料處理後的總評內容
 */
export const getGradingResults = (originalResults) => {
    let results = [];
    for (const g of originalResults) {
        const grading = {
            name: g.title,
            score: g.score,
            descriptions: g.comments,
        };
        results.push(grading);
    }

    return results;
};

/**
 * [寫作] 取得透過"優化文章模組"批改後的內容
 * @param {Array} originalParagraphs 原始批改後的段落內容
 * @returns {Array} 資料處理後的段落內容
 */
export const getRevisedParagraphs = (originalParagraphs) => {
    let paragraphs = [];
    for (const p of originalParagraphs) {
        // handle diff results
        let revisedText = '', htmlTexts = [];
        if (!p.diff_results) {
            revisedText = p.post_revision;
        } else {
            for (const el of p.diff_results) {
                const text = el[1].replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n|\r\n/g, '<br>');
                switch (el[0]) {
                    case '0': {
                        htmlTexts.push(`<span>${text}</span>`);
                        break;
                    }
                    case '-1': {  // 錯字或少字
                        htmlTexts.push(`<span style="font-weight: 700">${text}</span>`);
                        break;
                    }
                }
            }
            revisedText = htmlTexts.join('');
        }
        const paragraph = {
            revised: revisedText,
            originalTranslation: p.translation_of_text_cleaned,
            revisedTranslation: p.translation_of_revision,
            explanation: p.revision_explanation,
        };
        paragraphs.push(paragraph);
    }

    return paragraphs;
};
